import { RoundProgressComponent } from 'angular-svg-round-progressbar';
import { downgradeComponent } from '@angular/upgrade/static';


declare const angular: angular.IAngularStatic;

export default angular.module('kmi.lms.core')
  .directive(
    'roundProgress',
    downgradeComponent({ component: RoundProgressComponent }) as angular.IDirectiveFactory);
