import { FactoryProvider } from '@angular/core';
import { ISearchFilterOptionsUpgraded, ISearchFilterOptionsService } from '../../models/search-filters.models';


export abstract class LabelFilterService implements ISearchFilterOptionsService {
  abstract getOptions(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsUpgraded;
}

export function searchLabelFilterFactory(injector: angular.auto.IInjectorService): ISearchFilterOptionsService {
  return injector.get<ISearchFilterOptionsService>('searchFilterCourseLabel');
}

export const searchLabelFilterProvider: FactoryProvider = {
  provide: LabelFilterService,
  useFactory: searchLabelFilterFactory,
  deps: ['$injector']
};
