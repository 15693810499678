import * as _ from 'lodash';
import { EnvironmentSettings } from './environment-manager';


export class ThemeManager {
  constructor(private environmentSettings: EnvironmentSettings) { }

  setupTheme() {
    const themeName = this.getThemeName();

    return this.addLinkElement('stylesheet', ['styles/', this.getRevvedName(themeName), '.css'].join(''));
  }

  private addLinkElement(rel, href, sizes?) {
    return new Promise((resolve) => {
      const el = document.createElement('link');

      el.setAttribute('rel', rel);

      if (sizes) {
        el.setAttribute('sizes', sizes);
      }

      el.onload = function () {
        resolve(null);
      };

      el.setAttribute('href', href);
      document.getElementsByTagName('head')[0].appendChild(el);
    });
  }

  private getRevvedName(name) {
    return (<any>window).styleMap[name];
  }

  private getThemeName() {
    /**
     * Themes priority:
     * 1. Custom portal theme
     * 2. Default Portal theme
     */

    return this.getCustomPortalTheme() ||
      this.getDefaultPortalTheme() ||
      this.environmentSettings.globalConfig.projectName;
  }

  private checkTheme(name) {
    if (name) {
      name = [this.environmentSettings.globalConfig.projectName, name].join('-');

      return this.getRevvedName(name) && name;
    }

    return null;
  }

  private getCustomPortalTheme() {
    const name = _.get(this.environmentSettings.elmsEnvironment, 'siteSettings.theme.name');

    return name && this.checkTheme(name.toLowerCase());
  }

  private getDefaultPortalTheme() {
    const name = _.trim(this.environmentSettings.globalConfig.base, '/').toLowerCase();

    return this.checkTheme(name);
  }
}
