import { Component } from '@angular/core';
import { BackUrlService } from 'core/navigation';


@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html'
})
export class BackButtonComponent {
  static readonly selector = 'backButton';

  constructor(
    private backUrlService: BackUrlService
  ) {
  }

  goBack() {
    this.backUrlService.goBack();
  }
}
