import { FactoryProvider } from '@angular/core';
import { RawParams } from '@uirouter/core';


export abstract class BackUrlService {
  transitionToLastState: (params: RawParams) => void;
  hasHistory: () => boolean;
  forgetState: (state: any, params: RawParams) => void;
  updateState: (stateName: any, oldParams: RawParams, newParams: RawParams) => void;
  isSkipOnError: () => void;
  passThroughRedirect: (to: any, params?: RawParams, options?: any) => void;
  isForwardMovement: () => boolean;
  bypassHistory: () => void;
  clearDuplicateLastStateForRedirect: (stateTo: any) => void;
  goBack: (skipOnErrorValue?: boolean) => void;
  goBackInHistory: (skipOnErrorValue?: boolean) => void;
  external: {
    getBackUrlParam: (absUrl?: string) => string;
    getBackUrlParamValue: (absUrl: string) => string;
    setExternalBackUrl: () => void;
  };
}

export function backUrlServiceFactory(injector: angular.auto.IInjectorService): BackUrlService {
  return injector.get<BackUrlService>('backUrlService');
}

export const backUrlServiceProvider: FactoryProvider = {
  provide: BackUrlService,
  useFactory: backUrlServiceFactory,
  deps: ['$injector']
};
