import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';


@Injectable()
export class HttpRequestsCounterInterceptor implements HttpInterceptor {
  public activeRequestsStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activeRequests = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      this.activeRequestsStatus.next(true);
    }

    this.activeRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;

        if (this.activeRequests === 0) {
          this.activeRequestsStatus.next(false);
        }
      })
    );
  }
}
