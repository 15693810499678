<div [ngSwitch]="filter.mode">
  <single-choice-filter-dep *ngSwitchCase="'singleChoice'" [filter]="filter" [isOpen]="isOpen"
                            [fullScreen]="fullScreen"></single-choice-filter-dep>
  <multi-choice-filter-dep *ngSwitchCase="'multiChoice'" [filter]="filter" [isOpen]="isOpen"
                           [fullScreen]="fullScreen"></multi-choice-filter-dep>
  <searchable-multi-choice-filter-dep *ngSwitchCase="'searchableMultiChoice'" [filter]="filter" [isOpen]="isOpen"
                                      [fullScreen]="fullScreen"></searchable-multi-choice-filter-dep>
  <dates-choice-filter-dep *ngSwitchCase="'datesChoice'" [filter]="filter" [isOpen]="isOpen"
                           [fullScreen]="fullScreen"></dates-choice-filter-dep>
  <popup-multi-choice-filter-dep *ngSwitchCase="'popupMultiChoice'" [filter]="filter" [isOpen]="isOpen"
                                 [fullScreen]="fullScreen"></popup-multi-choice-filter-dep>
  <number-choice-filter-dep *ngSwitchCase="'numberChoice'" [filter]="filter" [isOpen]="isOpen"
                            [fullScreen]="fullScreen"></number-choice-filter-dep>
  <prompting-filter-dep *ngSwitchCase="'promptingFilter'" [filter]="filter" [isMultiple]="true"
                        [isOpen]="isOpen" [fullScreen]="fullScreen"></prompting-filter-dep>
  <prompting-filter-dep *ngSwitchCase="'singlePromptFilter'" [filter]="filter" [isOpen]="isOpen"
                        [fullScreen]="fullScreen"></prompting-filter-dep>
  <boolean-filter-dep *ngSwitchCase="'booleanFilter'" [filter]="filter" [isOpen]="isOpen"
                      [fullScreen]="fullScreen"></boolean-filter-dep>
  <composite-filter-dep *ngSwitchCase="'composite'" [filter]="filter" [isOpen]="isOpen"
                        [fullScreen]="fullScreen"></composite-filter-dep>
  <price-filter-dep *ngSwitchCase="'priceChoice'" [filter]="filter" [isOpen]="isOpen"
                    [fullScreen]="fullScreen"></price-filter-dep>
</div>
