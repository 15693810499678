<form ngForm="priceForm" #priceForm="ngForm" name="priceForm" [ngClass]="{'dropdown-filter-items overflow-hidden': filter.appearance === 'button'}">

  <div class="row no-margin-bottom" style="padding-top: 0px"  [ngClass]="{'dropdown-filter-item': filter.appearance === 'button'}">
    <div class="col-sm-12">
      <div class="checkbox">
        <label><input type="checkbox" [(ngModel)]="status.free" name="free"
                      (change)="changeFilter()"> Free</label>
      </div>
      <div class="checkbox">
        <label><input type="checkbox" [(ngModel)]="status.paid"  name="paid"
                      (change)="changeFilter()"> Paid</label>
      </div>
    </div>
  </div>
  <div class="row" style="padding-top: 0px; padding-bottom: 5px"  [ngClass]="{'dropdown-filter-item': filter.appearance === 'button'}">
    <div class="col-sm-6 col-xs-6"
      [ngClass]="{'has-error' : priceFrom.errors?.min ||
              priceFrom.errors?.max || priceFrom.errors?.pattern }">
      <label for="priceFrom" [ngClass]="{'text-muted':!status.paid}">{{filter.items[0].text}}</label>

      <input type="number" class="form-control" (change)="changeFilter()" [disabled]="!status.paid"
             id="priceFrom" #priceFrom="ngModel" name="priceFrom" min="0" [max]="status.maxPrice || 9999999"
             lmsPattern="^^[0-9]*(\.[0-9]{0,2})?$"
             step="0.01" [(ngModel)]="status.minPrice"/>
    </div>
    <div
      class="col-sm-6 col-xs-6"
      [ngClass]="{'has-error' : priceTo.errors?.min ||
                priceTo.errors?.max || priceTo.errors?.pattern }">
      <label for="priceTo" [ngClass]="{'text-muted':!status.paid}">{{filter.items[1].text}}</label>
      <input type="number" class="form-control" (change)="changeFilter()" [disabled]="!status.paid"
             id="priceTo" #priceTo="ngModel" name="priceTo" [min]="status.minPrice || 0" max="9999999"
             lmsPattern="^\d*((\.|\,)\d{1,2})?$" step="0.01" [(ngModel)]="status.maxPrice"/>
    </div>
  </div>

  <div class="row" *ngIf="priceFrom.dirty" [ngClass]="{'dropdown-filter-item': filter.appearance === 'button'}">
    <div class="col-xs-12">
      <div [ngClass]="{'has-error' : (priceFrom.dirty && priceFrom.errors?.pattern ||
            priceFrom.errors?.min || priceFrom.errors?.max) || (priceTo.dirty &&
            priceTo.errors?.pattern || priceTo.errors?.min || priceTo.errors?.max)}">

            <p class="help-block no-margin-all" style="padding-bottom: 0px" *ngIf="priceFrom.dirty &&
              priceFrom.errors?.pattern || priceFrom.errors?.min || priceFrom.errors?.max">
              The minimal price is out of range.
            </p>
            <p class="help-block no-margin-all" *ngIf=" priceTo.dirty && priceTo.errors?.pattern ||
              priceTo.errors?.min || priceTo.errors?.max">
              The maximal price is out of range.
            </p>
      </div>
    </div>
  </div>
  </form>

<div class="divider" *ngIf="!anyEvent"></div>

<div class="dropdown-filter-item" *ngIf="!anyEvent" style="padding:10px">
  <button type="button"
          [disabled]="!status.changed || status.paid &&
          (priceFrom.errors?.pattern || priceFrom.errors?.min || priceFrom.errors?.max ||
           priceTo.errors?.pattern || priceTo.errors?.min || priceTo.errors?.max)"
          class="btn btn-default btn-block"
          (click)="applyFilter()">Apply
  </button>


</div>

