import { downgradeComponent } from '@angular/upgrade/static';
import { AccreditationAgencyInfoComponent } from 'modules/credits/accreditation-agency-info.component';


declare const angular: angular.IAngularStatic;

export default angular.module('kmi.lms.core')
  .directive(
    AccreditationAgencyInfoComponent.selector,
    downgradeComponent({ component: AccreditationAgencyInfoComponent }) as angular.IDirectiveFactory);
