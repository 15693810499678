import { downgradeInjectable } from '@angular/upgrade/static';
import { EventService } from 'modules/events/services/event.service';
import { lastValueFrom, map } from 'rxjs';


declare const angular: angular.IAngularStatic;

function moduleRun(entityDataService, $injector) {
  entityDataService.registerLoader('event', function (id) {
    const eventService = $injector.get('Event');

    return lastValueFrom(eventService.get(Number(id))
      .pipe(
        map(event => Object.assign({ $resolved: true }, event))
      ));
  });
}

export default angular
  .module('kmi.lms.core')
  .factory('Event', downgradeInjectable(EventService))
  .run(moduleRun);
