import { FactoryProvider } from '@angular/core';
import {
  ISearchFilterUpgraded, ISearchFilterOptionsUpgraded, ISearchFilterTypes
} from '../../models/search-filters.models';
import { InjectorService } from 'core/types';
import { Observable, concat, from, map, reduce } from 'rxjs';


// TODO CUSTOM MODULE ONLY FOR TRAIN...
export abstract class TrainSearchFilterUpgradedProvider {
  CompetencyNodeFilter: { new(): ISearchFilterUpgraded };
  SessionLabelsFilter: { new(): ISearchFilterUpgraded };
  GroupTagsFilter: { new(): ISearchFilterUpgraded };
  ObjectFilter: { new(): ISearchFilterUpgraded };
  CourseAttributeFilter: { new(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded };
  PerlcCourseAttributeFilter: { new(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded };
  GroupsFilter: { new(): ISearchFilterUpgraded };
  SingleGroupFilter: { new(): ISearchFilterUpgraded };
}

export function searchFilterTrainProviderFactory(injector: InjectorService): TrainSearchFilterUpgradedProvider {
  return injector.get<TrainSearchFilterUpgradedProvider>('trainSearchFilters');
}

export const searchFiltersTrainProvider: FactoryProvider = {
  provide: TrainSearchFilterUpgradedProvider,
  useFactory: searchFilterTrainProviderFactory,
  deps: ['$injector']
};

// TODO Temporary solution until `trainSearchFilters` is implemented.
export function getTrainSearchFilters(provider: TrainSearchFilterUpgradedProvider): Observable<ISearchFilterTypes> {
  const groupsFilter = new provider.GroupsFilter();
  const tagsFilter = new provider.GroupTagsFilter();
  const labelsFilter = new provider.SessionLabelsFilter();

  return concat(
    from(tagsFilter.load()),
    from(groupsFilter.load()),
    from(labelsFilter.load()),
  ).pipe(reduce(() => null), map(() => {
    return {
      ui: [groupsFilter, labelsFilter, tagsFilter],
      all: [groupsFilter, labelsFilter, tagsFilter],
    };
  }));
}
