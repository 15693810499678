import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpContextToken
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'core/services';
import { catchError, Observable, throwError, EMPTY } from 'rxjs';


export const IGNORE_UNAUTHORIZED_REQUESTS = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpRequestPermitInterceptor implements HttpInterceptor {
  private authLoginRequested: boolean;

  constructor(private globalService: GlobalService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * Intercepts HTTP requests to handle unauthorized errors selectively.
     *
     * In this application, we use a small custom back-end service, such as "audit",
     * that might return unauthorized responses under certain conditions.
     * For these cases, an unauthorized response from the back-end should not trigger
     * a global logoff for the entire application.
     *
     * This interceptor checks the `IGNORE_UNAUTHORIZED_REQUESTS` context token on
     * the HTTP request to determine whether unauthorized errors should be ignored.
     * If the token is present and set, unauthorized errors are handled locally,
     * avoiding any global logout mechanism.
     */
    const ignoreUnauthorizedRequests = request.context.get(IGNORE_UNAUTHORIZED_REQUESTS);

    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => this.handleError(e, ignoreUnauthorizedRequests)),
    );
  }

  private handleError(error: HttpErrorResponse, ignoreUnauthorizedRequests: boolean) {
    if (error.status === 401) {
      if (!this.authLoginRequested && !ignoreUnauthorizedRequests) {
        this.globalService.onAuthLoginRequired();
        this.authLoginRequested = true;
      }

      return EMPTY;
    }

    return throwError(() => error);
  }
}
