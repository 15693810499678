<div *ngIf="filter.visible && filter.items.length">
  <ngb-accordion *ngIf="!fullScreen && filter.appearance === 'panel'"
                 #acc="ngbAccordion"
                 [activeIds]="isOpen?'panel-'+filter.term:''"
                 [ngClass]="{'search-accordion-list':filter.popupOptions && filter.items.length >= 10}">
    <ngb-panel [id]="'panel-'+filter.term">
      <ng-template ngbPanelTitle>
        <span class="pull-right fa fa-fw"
              [ngClass]="[acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
        {{filter.label}}
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngIf="!filter.popupOptions || filter.items.length < 10">
          <div *ngIf="!filter.selectedItems.length">
            <div class="content-list-item search-item search-input"
                 *ngIf="filter.items.length > (filter.displayLimit || 10)">
              <input type="search" [(ngModel)]="searchQuery" class="form-control" id="filterSearch"
                     placeholder="Filter items" />
              <label for="filterSearch" class="sr-only">Filter</label>
            </div>

            <div *ngFor="let item of filteredFilterItems" class="filter-item">
              <a *ngIf="item.value" href="#" (click)="filter.exec(item.value)"><span [innerHtml]="item.text"></span>
                <selected-filter-rating-dep [item]="item"
                                            *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
              </a>
              <a *ngIf="item.link" uiSref="main.search" [uiParams]="item.link" [uiOptions]="{inherit: false}"><span
                      [innerHtml]="item.text"></span>
                <selected-filter-rating-dep [item]="item"
                                            *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
              </a>
            </div>

            <!--Expand/Collapse-->
            <div *ngIf="!searchQuery">
              <div *ngIf="filter.displayLimit && filter.items.length > displayLimit">
                <hr class="no-margin-top no-margin-bottom" />
                <a href="#" title="more" (click)="moreItems();">more...</a>
              </div>
              <div *ngIf="filter.displayLimit && filter.displayLimit < displayLimit">
                <hr class="no-margin-top no-margin-bottom" />
                <a href="#" title="less" (click)="lessItems();">less</a>
              </div>
            </div>
          </div>

          <div *ngIf="filter.selectedItems.length" class="filter-item">
            <div>
              <a href="#" (click)="filter.clearSelection()"><span
                      class="fa fa-chevron-left inline-block sm-margin-right"></span>Any
                {{filter.clearItemsLabel||filter.label}}</a>
            </div>
            <div *ngFor="let item of filter.selectedItems">
              <span [innerHtml]="item.text"></span>
              <selected-filter-rating-dep [item]="item"
                                          *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
            </div>
          </div>
        </div>

        <!--Show popup filter when filter items more or equal than 10 and exists popup options-->
        <ul class="content-list" *ngIf="filter.popupOptions && filter.items.length >= 10">
          <li class="content-list-item" *ngIf="filter.selectedItems.length>0">
            <div class="flex">
              <div class="flex-row">
                <div class="flex-col flex-col-grow">
                  <div *ngFor="let item of filter.selectedItems">
                    <a href="#" title="" (click)="openFilter()"><span [innerHtml]="item.text"></span></a>
                    <selected-filter-rating-dep [item]="item"
                                                *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="content-list-item">
            <div class="flex">
              <div class="flex-row">
                <div class="flex-col flex-col-grow">
                  <button type="button" class="btn btn-success btn-block" *ngIf="filter.selectedItems.length <= 0"
                          (click)="openFilter()">Select Filter
                  </button>
                  <button type="button" class="btn btn-danger btn-block" *ngIf="filter.selectedItems.length > 0"
                          (click)="filter.clearSelection()">Remove Filter
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div *ngIf="!fullScreen && filter.appearance === 'button'">
    <div class="btn-group btn-block" ngbDropdown #dropDown="ngbDropdown" [autoClose]="'outside'"
         (openChange)="isOpen=$event">
      <button id="SearchFilters" type="button" class="btn btn-default btn-block"
              ngbDropdownToggle>
        {{filter.label}}
        <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu>
        <div class="dropdown-filter-items">
          <div *ngIf="!selectedItems.length">
            <div class="content-list-item search-item search-input"
                 *ngIf="filter.items.length > (filter.displayLimit || 10)">
              <input type="search" [(ngModel)]="searchQuery" (change)="searchQueryChanged()" class="form-control"
                     id="filterSearchDropDown"
                     placeholder="Filter items" />
              <label for="filterSearchDropDown" class="sr-only">Filter</label>
            </div>

            <div *ngFor="let item of filteredFilterItems" class="dropdown-filter-item">
              <a *ngIf="item.value" href="#" (click)="addItem($event, item);"><span [innerHtml]="item.text"></span>
                <selected-filter-rating-dep [item]="item"
                                            *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
              </a>
              <a *ngIf="item.link" uiSref="main.search" [uiParams]="item.link" [uiOptions]="{inherit: false}"><span
                      [innerHtml]="item.text"></span>
                <selected-filter-rating-dep [item]="item"
                                            *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
              </a>
            </div>
          </div>

          <div *ngIf="selectedItems.length" class="filter-item">
            <div class="dropdown-filter-item">
              <a href="#" (click)="clearSelection()"><span
                      class="fa fa-chevron-left inline-block sm-margin-right"></span>Any
                {{filter.clearItemsLabel||filter.label}}</a>
            </div>
            <div *ngFor="let item of selectedItems" class="dropdown-filter-item">
              <span [innerHtml]="item.text"></span>
              <selected-filter-rating-dep [item]="item"
                                          *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <div class="dropdown-filter-item" style="padding: 10px">
          <button type="button"
                  [disabled]="!changed" class="btn btn-default btn-block"
                  (click)="applySelection(); dropDown.close()">Apply
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen && !filter.popupOptions">

    <ul class="content-list">
      <li class="content-list-item search-item search-input">
        <input type="search" id="filterSearch0" [(ngModel)]="searchQuery" class="form-control"
               placeholder="Filter items" />
        <label for="filterSearch0" class="sr-only">Filter</label>
        <span class="fa fa-search"></span>
      </li>
    </ul>

    <ul class="content-list" *ngFor="let item of filteredFilterItems"
        (click)="filter.exec(item.value)">
      <li class="content-list-item search-item" [ngClass]="{'search-item-selected':item.selected}">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <span *ngIf="item.value" (click)="item.selected = !item.selected">
                <span [innerHtml]="item.text"></span>
                <selected-filter-rating-dep [item]="item"
                                            *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
              </span>

              <a *ngIf="item.link" uiSref="main.search" [uiParams]="item.link" [uiOptions]="{inherit: false}"><span
                      [innerHtml]="item.text"></span>
                <selected-filter-rating-dep [item]="item"
                                            *ngIf="filter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
              </a>
            </div>
            <div class="flex-col flex-col-2x text-right text-muted">
              <span class="fa fa fa-check" *ngIf="item.selected"></span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="fullScreen && filter.popupOptions">
    <div [ngSwitch]="filter.popupOptions.component">
      <course-attribute-filter-dep *ngSwitchCase="'courseAttributeFilter'"
                                   [filter]="filter"></course-attribute-filter-dep>
      <competency-filter-dep *ngSwitchCase="'competencyFilter'" [filter]="filter"></competency-filter-dep>
      <session-label-filter-dep *ngSwitchCase="'sessionLabelFilter'" [filter]="filter"></session-label-filter-dep>
      <group-tags-search-filter-dep *ngSwitchCase="'groupTagsSearchFilter'"
                                    [filter]="filter"></group-tags-search-filter-dep>
    </div>
  </div>
</div>
