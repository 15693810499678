import { downgradeInjectable } from '@angular/upgrade/static';
import { MarkdownManagerService } from 'components/markdown/services/markdown-manager.service';


declare const angular: angular.IAngularStatic;


export default angular
  .module('kmi.lms.core')
  .factory('markdownManagerServiceUpgraded', downgradeInjectable(MarkdownManagerService));
