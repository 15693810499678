import { NgModule } from '@angular/core';
import { SearchService } from 'modules/search/services';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SavedSearchesServive } from 'modules/search/services/saved-searches.service';
import { searchRoutingBMSModule } from './search-routing-bms.module';
import { SearchBMSComponent } from './components/search-bms.component';


@NgModule({
  imports: [
    UIRouterModule,
    SearchBMSComponent,
    UIRouterUpgradeModule.forChild({ states: searchRoutingBMSModule }),
  ],
  providers: [
    SearchService,
    SavedSearchesServive
  ]
})
export class SearchBMSModule {}
