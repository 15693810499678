import { FactoryProvider } from '@angular/core';


export abstract class RootScopeService {
  abstract broadcast(eventName: string, ...args: any[]): void;
}

export function rootScopeServiceFactory(injector: angular.auto.IInjectorService): RootScopeService {
  return injector.get<RootScopeService>('rootScopeService');
}

export const rootScopeServiceProvider: FactoryProvider = {
  provide: RootScopeService,
  useFactory: rootScopeServiceFactory,
  deps: ['$injector']
};
