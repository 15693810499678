import { FactoryProvider } from '@angular/core';
import { ElmsAuthService } from 'ajs/modules/app/authorization/elms-auth-service';


export function elmsAuthServiceFactory(injector: angular.auto.IInjectorService): ElmsAuthService {
  return injector.get<ElmsAuthService>('elmsAuthService');
}

export const elmsAuthServiceProvider: FactoryProvider = {
  provide: ElmsAuthService,
  useFactory: elmsAuthServiceFactory,
  deps: ['$injector']
};
