import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LmsNotificationsDirective, LmsMainContainerDirective } from './components';
import { authorizationServiceProvider, elmsAuthServiceProvider, currentUserServiceProvider } from './authorization';
import { globalConfigProvider, notificationServiceProvider } from './environment';
import { backUrlServiceProvider, locationHistoryServiceProvider } from './navigation';
import { httpInterceptorProviders } from './http-interceptors';
import { errorHandlerServiceProvider } from './services/server-error-handler.service.ajs-upgraded-provider';
import { urlVersionServiceProvider } from './services/url-version.service.ajs-upgraded-provider';
import { entityDataServiceProvider, EnvironmentService, HttpCacheMapService } from 'core/services';
import { markdownRenderServiceProvider } from './services/markdown-render.service.ajs-upgraded-provider';
import { metaServiceProvider } from './services/meta.service.ajs-upgraded-provider';
import {
  gtagServiceProvider, securityServiceProvider,
  userSettingsServiceProvider, rootScopeServiceProvider
} from './services';
import { globalServiceProvider } from './services/global.service.ajs-upgraded-provider';
import { GeoService } from 'core/services/geo/geo.service';
import { ScrollService } from 'core/services/scroll.service';
import { NavigationService, BackButtonComponent } from 'core/navigation';
import { cacheFactoryProvider } from 'core/cache-factory.service.ajs-upgraded-provider';
import { AiHelperService } from 'core/ai-tools/services/ai-helper.service';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { LmsCookiesService } from 'core/services/cookies/lms-cookies.service';
import { SamlService } from 'modules/integrations/services/saml.service';
import { navigationJsServiceProvider } from 'core/navigation/services/navigationjs.service.ajs-upgraded-provider';
import { DirectBackButtonComponent } from 'core/navigation/components/direct-back-button.component';
import { ErrorsService } from 'core/services/errors.service';


@NgModule({
  imports: [
    CommonModule,
    LoadingBarHttpClientModule
  ],
  declarations: [
    LmsMainContainerDirective,
    LmsNotificationsDirective,
    BackButtonComponent,
    DirectBackButtonComponent
  ],
  providers: [
    globalServiceProvider,
    authorizationServiceProvider,
    elmsAuthServiceProvider,
    currentUserServiceProvider,
    globalConfigProvider,
    notificationServiceProvider,
    backUrlServiceProvider,
    locationHistoryServiceProvider,
    httpInterceptorProviders,
    errorHandlerServiceProvider,
    urlVersionServiceProvider,
    HttpCacheMapService,
    markdownRenderServiceProvider,
    metaServiceProvider,
    userSettingsServiceProvider,
    rootScopeServiceProvider,
    gtagServiceProvider,
    securityServiceProvider,
    entityDataServiceProvider,
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 500 } },
    GeoService,
    ScrollService,
    ErrorsService,
    navigationJsServiceProvider,
    NavigationService,
    cacheFactoryProvider,
    AiHelperService,
    SiteSettingsService,
    EnvironmentService,
    LmsCookiesService,
    SamlService
  ],
  exports: [
    LmsMainContainerDirective,
    LmsNotificationsDirective,
    LoadingBarHttpClientModule,
    BackButtonComponent,
    DirectBackButtonComponent
  ]
})
export class LmsCoreModule { }
