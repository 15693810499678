<div *ngIf="filter.visible">
  <div *ngIf="!fullScreen">
    <ngb-accordion *ngIf="!filter.appearance || filter.appearance === 'panel'"
                   class="truncate-labels"
                   [activeIds]="isOpen?'panel-'+filter.term:''"
                   #acc='ngbAccordion'>
      <ngb-panel [id]="'panel-'+filter.term">
        <ng-template ngbPanelTitle>
          <span class="pull-right fa fa-fw"
                [ngClass]="[acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
          {{filter.label}}
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="filter.loading">
            <div class="load-spinner"></div>
          </div>
          <ng-select *ngIf="!filter.loading"
                     [items]="choices$ | async"
                     [multiple]="filter.multiple"
                     [placeholder]="filter.placeholder || 'Start typing a name...'"
                     bindLabel="text"
                     bindValue="id"
                     [clearable]="false"
                     [minTermLength]="3"
                     [autoFocus]="true"
                     [(ngModel)]="selectedItems"
                     [addTag]="false"
                     [trackByFn]="trackByFn"
                     (add)="execFilter($event)"
                     (remove)="execFilter($event)"
                     [loading]="choicesLoading"
                     [compareWith]="compareFn"
                     [typeahead]="choicesInput$">
          </ng-select>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div *ngIf="filter.appearance === 'button'" class="btn-group btn-block"
         ngbDropdown [autoClose]="'outside'" is-open="status.isOpen">
      <button id="SearchFilters" type="button"
              class="btn btn-default btn-block" ngbDropdownToggle>
        {{filter.label}} <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu class="dropdown-menu-right filter-dropdown">
        <div>
          <div *ngIf="filter.loading">
            <div class="load-spinner"></div>
          </div>

          <ng-select *ngIf="!filter.loading"
                     [items]="choices$ | async"
                     [multiple]="filter.multiple"
                     [placeholder]="filter.placeholder || 'Start typing a name...'"
                     bindLabel="text"
                     bindValue="id"
                     [clearable]="false"
                     [minTermLength]="3"
                     [autoFocus]="true"
                     [(ngModel)]="selectedItems"
                     [addTag]="false"
                     [trackByFn]="trackByFn"
                     (add)="execFilter($event)"
                     (remove)="execFilter($event)"
                     [loading]="choicesLoading"
                     [compareWith]="compareFn"
                     [typeahead]="choicesInput$">
          </ng-select>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="fullScreen" class="">

    <ul class="content-list">
      <li class="content-list-item search-item">
        <div class="mobile-prompt-item">
          <ng-select *ngIf="!filter.loading"
                     [items]="choices$ | async"
                     [multiple]="filter.multiple"
                     [placeholder]="filter.placeholder || 'Start typing a name...'"
                     bindLabel="text"
                     bindValue="id"
                     [clearable]="false"
                     [minTermLength]="3"
                     [autoFocus]="true"
                     [(ngModel)]="selectedItems"
                     [addTag]="false"
                     [trackByFn]="trackByFn"
                     (add)="execFilter($event)"
                     (remove)="execFilter($event)"
                     [loading]="choicesLoading"
                     [compareWith]="compareFn"
                     [typeahead]="choicesInput$">
          </ng-select>
        </div>
      </li>
    </ul>
  </div>
</div>
