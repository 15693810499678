import { FactoryProvider } from '@angular/core';


export abstract class NavigationJsService {
  extractTarget: () => void;
}

export function navigationJsServiceFactory(injector: angular.auto.IInjectorService): NavigationJsService {
  return injector.get<NavigationJsService>('navigationService');
}

export const navigationJsServiceProvider: FactoryProvider = {
  provide: NavigationJsService,
  useFactory: navigationJsServiceFactory,
  deps: ['$injector']
};
