import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { lastValueFrom, map, Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils/elms-utils';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';


export enum SiteSettingsRequestPaths {
  GET = '/a/site_settings/:siteSettingId/',
  PUT = '/a/site_settings/:siteSettingId/',
  POST = '/a/site_settings/',
  QUERY = '/a/site_settings/',
  HISTORY = '/a/site_settings/:siteSettingId/history/',
  CURRENT = '/a/about_elms/site_setting/',
  DEFAULTS = '/a/site_settings/defaults/'
}


@Injectable()
export class SiteSettingsService {
  private defaultProps: Partial<ISiteSettings> = {
    active: true,
  };

  private readonly _currentSiteSetting: ISiteSettings;

  constructor(protected http: HttpClient) {
    if ((<any>window).elmsEnvironment.siteSettings) {
      this._currentSiteSetting = (<any>window).elmsEnvironment.siteSettings;
    }
  }

  public get(siteSettingId: number): Observable<ISiteSettings> {
    return this.http.get<ISiteSettings>(
      ElmsUtils.formatUrl(SiteSettingsRequestPaths.GET, { siteSettingId })
    ).pipe(
      map(resource => Object.assign({}, this.defaultProps, resource))
    );
  }

  public async getCurrent(): Promise<ISiteSettings> {
    if (this._currentSiteSetting) {
      return this._currentSiteSetting;
    }

    return lastValueFrom(this.http.get<ISiteSettings>(
      SiteSettingsRequestPaths.CURRENT,
      { context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE) }
    ).pipe(
      map(siteSettings => Object.assign({}, this.defaultProps, siteSettings))
    ));
  }
}
