import { FactoryProvider } from '@angular/core';


export abstract class ErrorHandlerService {
  handleForbiddenError: (reason: any, callback?: (msg:string) => void) => Promise<any>;
}

export function errorHandlerServiceFactory(injector: angular.auto.IInjectorService): ErrorHandlerService {
  return injector.get<ErrorHandlerService>('serverErrorHandlerService');
}

export const errorHandlerServiceProvider: FactoryProvider = {
  provide: ErrorHandlerService,
  useFactory: errorHandlerServiceFactory,
  deps: ['$injector']
};
