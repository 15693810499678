import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { UrlParamHandler, IUrlParams } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICompetency } from 'modules/competency/models/competency.model';
import { ISearchFilterItemUpgraded, ISearchFilterUpgraded } from 'app/deprecated/search/models/search-filters.models';


@Component({
  selector: 'competency-filter-dep',
  templateUrl: './competency-filter.component.html',
})
export class CompetencyFilterDepComponent implements OnInit, OnDestroy {
  @Input() filter: ISearchFilterUpgraded;
  competencies: ICompetency[];
  selectedItems: ISearchFilterItemUpgraded[];
  modalInstance?: NgbActiveModal;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;
  constructor(
    private urlService: UrlService,
  ) { }

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.competencies = this.filter.competencies;
      this.selectedItems = this.filter.selectedItems;
    }
  }

  selectedItemsChanged(items: ISearchFilterItemUpgraded[]) {
    // Prevent ExpressionChangedAfterItHasBeenCheckedError
    // from the upgraded component's bindings
    requestAnimationFrame(() => {
      this.selectedItems = items;

      if (!this.modalInstance) {
        this.applyFilter();
      }
    });
  }

  applyFilter() {
    this.filter.clearSelection();
    _.forEach(this.selectedItems, (item) => {
      this.filter.exec(item.id);
    });

    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  cancelModal() {
    this.modalInstance?.dismiss();
  }
}
