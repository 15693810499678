import { FactoryProvider } from '@angular/core';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import {
  ISearchFilterOptionsUpgraded,
  ISearchFilterOptionsService, ISearchFilterTypes,
  ISearchFilterUpgraded,
  ISearchFilterValueTerm
} from '../../models/search-filters.models';
import { ISearchFilterTerm } from 'modules/search/models/search-filters.models';


export type IFilterClass = { new(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded };

export abstract class FilterConstructor {
  extend: (params: object) => IFilterClass;
}

export abstract class BaseFilterService {
  sortTerms: ISearchFilterValueTerm[];
  searchSortTerms: ISearchFilterValueTerm[];
  adminSortTerms: ISearchFilterTerm[];
  adminShowResultsTerms: ISearchFilterTerm[];

  MultipleTermFilter: FilterConstructor;
  CourseFormatFilter: IFilterClass;
  TagFilter: IFilterClass;
  CreditTypeFilter: IFilterClass;
  TimeRangeFilter: IFilterClass;
  ObjectFilter: IFilterClass;
  UpdatedFilter: IFilterClass;
  ScheduleFilter: IFilterClass;
  CommonFilter: IFilterClass;
  CoursePriceFilter: IFilterClass;
  ShortcutsFilter: IFilterClass;
  CourseRatingFilter: IFilterClass;
  SubjectFilter: IFilterClass;
  NumberFilter: IFilterClass;
  TargetAudienceFilter: IFilterClass;
  LsFilter: IFilterClass;
  CompetencyFilter: IFilterClass;
  CourseLanguageFilter: IFilterClass;
  CourseFormatTypeFilter: IFilterClass;

  abstract associateFilters(parent: ISearchFilterUpgraded, child: ISearchFilterUpgraded): void;
  abstract getFilter(
    filter: ISearchFilterOptionsService, options?: Partial<ISearchFilterOptionsUpgraded>
  ): ISearchFilterUpgraded;

  abstract loadFilters(): ISearchFilterTypes;
  abstract createFilter(name: string): ISearchFilterUpgraded;
  abstract onInit(init: () => ISearchFilterTypes): void;
}

export function searchFilterProviderFactory(injector: angular.auto.IInjectorService): BaseFilterService {
  return injector.get<BaseFilterService>('searchFilters');
}

export const searchFiltersProvider: FactoryProvider = {
  provide: BaseFilterService,
  useFactory: searchFilterProviderFactory,
  deps: ['$injector']
};

// TODO Temporary solution until `searchFilters` is implemented.
export function getCalendarFilters(
  provider: BaseFilterService, anonymous: boolean, stateService: StateService
): ISearchFilterTypes {
  const MultipleTermFilter = provider.MultipleTermFilter.extend({
    _initSelectedItems: function () {
      this._super();

      if (anonymous && _.some(this.selectedItems, { term: 'user_courses', value: 'true' })) {
        setTimeout(() => stateService.go('prompt.login', null, { location: 'replace' }));
      }
    },
    init: function (options: object) {
      Object.assign(this, {
        label: 'Filters',
        appearance: 'button',
        mode: 'multiChoice',
        multiChoice: true, //For backward compatibility
        open: true,
        items: [{
          text: 'Your events',
          term: 'user_courses',
          value: 'true'
        }]
      }, options);
      this._initSelectedItems();
    }
  });
  const filter = new MultipleTermFilter();

  return {
    ui: [filter],
    all: [filter],
  };
}
