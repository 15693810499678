<div *ngIf="isCourseItem(rowItem) && mode === 'tiles'">
  <horizontal-course-tile-modern [course]="rowItem" mode="component" [trackingName]="'search_result'"
                                 [hideRegManagement]="true" [disabled]="loading"
                                 [showMandatory]="true"
                                 [smallView]="smallView"></horizontal-course-tile-modern>
</div>

<div *ngIf="mode === 'simpleList' || mode === 'expandedList'">
  <div class="flex">
    <div class="flex-row">
      <div class="flex-col" *ngIf="isCourseItem(rowItem) && rowItem.price">
        <span class="label label-price">{{formatPrice(rowItem.price) }}</span>
      </div>

      <!--Title-->
      <div class="flex-col flex-col-grow">
        <div class="text-truncate-link" [ngSwitch]="rowItem.type">
          <a *ngSwitchCase="'author'" uiSref="main.courseAuthorProfile" [uiParams]="({courseAuthorId: rowItem.id})"
             [innerHtml]="rowItem.title"></a>
          <a *ngSwitchCase="'user'" uiSref="main.user_network" [uiParams]="{userId: rowItem.id}"
             [innerHtml]="rowItem.title" data-testid="search-results-user-link"></a>
          <a *ngSwitchDefault [uiSref]="'main.'+rowItem.type" [uiParams]="({id: rowItem.id})"
             [innerHtml]="rowItem.title" data-testid="search-results-item-link"></a>
        </div>
      </div>

      <!--Content Type-->
      <div class="flex-col text-right">
        <span class="search-label-list">
          <span *ngIf="isDocument(rowItem)" class="label label-document">
            <span class="hidden-xs">Doc</span>
            <span class="visible-xs-inline fa fa-file"></span>
          </span>
          <span *ngIf="isAuthor(rowItem)" class="label label-user">
            <span class="hidden-xs">Author</span>
            <span class="visible-xs-inline fa-solid fa-user"></span>
          </span>
          <span *ngIf="isUser(rowItem)" class="label label-user">
            <span class="hidden-xs">Profile</span>
            <span class="visible-xs-inline fa-solid fa-user"></span>
          </span>
          <span *ngIf="isResource(rowItem)" class="label label-eknowledge">
            <span class="hidden-xs">{{rowItem.typeName || 'Resource' }}</span>
            <span class="visible-xs-inline fa fa-file"></span>
          </span>
          <span *ngIf="isCourseItem(rowItem)" class="label label-course">
            <span class="hidden-xs">{{rowItem.labelName || rowItem.formatName}}</span>
            <span class="visible-xs-inline fa-solid fa-book"></span>
          </span>
          <span *ngIf="isBlog(rowItem)" class="label label-blog">
            <span class="hidden-xs">{{rowItem.typeName || rowItem.type || 'Blog' }}</span>
            <span class="visible-xs-inline fa fa-comments"></span>
          </span>

          <span *ngIf="isUndefined(rowItem)" class="label {{'label-' + rowItem.type}}">
            <span class="hidden-xs">{{rowItem.type}}</span>
            <span class="visible-xs-inline fa"
                  [ngClass]="{'fa-book': rowItem.type === 'course',
                   'fa-user': rowItem.type === 'user',
                   'fa-table-cells': isCourseItem(rowItem) && rowItem.labelName === 'Collection',
                   'fa-file':rowItem.type === 'resource',
                   'fa-message':rowItem.type === 'discussion'
                  }"></span>
          </span>
        </span>
      </div>

    </div>
    <div class="flex-row"
         *ngIf="isCourseItem(rowItem) && (rowItem.course_credits?.length || rowItem.length) && mode === 'simpleList'">
      <div class="flex-col flex-col-grow text-muted"><span *ngIf="rowItem.course_credits?.length"> Credits: <span
                *ngFor="let credit of rowItem.course_credits; let last=last;">{{credit.name}}<span
                  *ngIf="credit.amount">&nbsp;({{formatDigit(credit.amount, 3)}})</span>{{last ? '' : ', '}}
          </span></span>
      </div>
      <div class="flex-col text-right text-muted" *ngIf="rowItem.length">{{rowItem.length}}h to complete</div>
    </div>
  </div>
</div>
