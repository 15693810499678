import '../src/styles/tailwind.css';
import '../src/styles/pre-theme.scss';

//#region NODE_CSS
import 'angular/angular-csp.css';
import 'fullcalendar/dist/fullcalendar.min.css';
import 'blueimp-file-upload/css/jquery.fileupload.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'katex/dist/katex.min.css';
import 'ui-select/dist/select.css';

import 'bootstrap-markdown/css/bootstrap-markdown.min.css';
//#endregion

import 'zone.js';
import '@angular/localize/init';
import 'reflect-metadata';
import '@angular/compiler';
import 'jquery';
import 'lodash/lodash.min';
import 'angular';
import 'angular-ui-tree';
import 'bootstrap';
import 'angular-ui-bootstrap';
import 'angular-http-auth';
import 'ladda';
import 'ladda/dist/spin.min.js';
import 'angular-ladda/dist/angular-ladda.min';
import 'angular-resource';
import 'angular-animate';
import 'angular-cookies';
import 'angular-sanitize';
import 'angular-aria';
import 'angular-local-storage';
import 'angular-ui-router';
import 'angular-ui-mask';
import 'angular-linkify';
import 'ui-select';
import 'angular-filter';
import 'angular-mocks';

import 'font-awesome-regular';
import 'font-awesome-solid';
import 'font-awesome-brands';

import 'blueimp-file-upload/js/jquery.fileupload.js';
import 'blueimp-file-upload/js/jquery.fileupload-process.js';
import 'blueimp-file-upload/js/jquery.fileupload-validate.js';
import 'blueimp-file-upload/js/jquery.fileupload-angular.js';

import 'angular-ui-calendar';
import 'fullcalendar/dist/fullcalendar.min';
import 'bootstrap-markdown/js/bootstrap-markdown.js';

//#region QRCode
import qrcode from 'qrcode-generator';
(<any>window).qrcode = qrcode;
import 'angular-qrcode';
//#endregion
