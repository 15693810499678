import { Component, Input } from '@angular/core';
import { ISearchFilterUpgraded } from '../../models/search-filters.models';


@Component({
  selector: 'search-filter-view-dep',
  templateUrl: './search-filter-view.component.html',
})
export class SearchFilterViewDepComponent {
  static readonly selector = 'searchFilterView';

  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
}
