import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { UrlParamHandler, IUrlParams } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { ISearchItem, SearchService } from 'modules/search/services';
import { SearchResultMode } from 'modules/search/models/search.model';
import { Unsubscribable } from 'rxjs';


@Component({
  selector: 'search-result-dep',
  templateUrl: './search-result.component.html'
})
export class SearchResultDepComponent implements  OnInit, OnDestroy {
  static readonly selector = 'searchResult';

  @Input() mode: SearchResultMode;
  @Input() searchExportLink: string;
  @Input() itemsPerPage = 20;
  @Input() smallView: boolean;
  @Output() promise = new EventEmitter<boolean>();
  @Output() itemCountChanged = new EventEmitter<number>();

  items: ISearchItem[] = [];
  currentPage = 1;
  private _itemCount:number;
  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;
  private requestSubscriber?: Unsubscribable;

  constructor(
    private urlService:UrlService,
    private searchService: SearchService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
  ) { }

  get loading() {
    return !!this.requestSubscriber;
  }

  set itemCount(value: number) {
    this._itemCount = value;
    this.itemCountChanged.emit(value);
  }

  get itemCount() {
    return this._itemCount;
  }

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
    this.clearRequestSubscriber();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search());

    if (!_.isEqual(params, this.urlParams)) {
      if (params.page && this.urlParams && !_.isEqual(_.omit(params, ['page']), _.omit(this.urlParams, ['page']))) {
        setTimeout(() => {
          this.stateService.go(
            this.activeState.current,
            Object.assign({}, params, { page: null })
          );
        });
      }
      else {
        this.urlParams = params;
        this.ngOnUrlParamsChange(params);
      }
    }
  }

  ngOnUrlParamsChange(params: IUrlParams) {
    this.currentPage = Number(params.page) || 1;
    this.search(params);
  }

  pageChanged(page: number) {
    const filters = Object.assign({}, this.urlParams, { page: page > 1 && page || null });

    this.stateService.go(this.activeState.current, filters);
  }

  private search(searchQuery: IUrlParams) {
    const query = Object.assign({}, searchQuery, {
      count: this.itemsPerPage,
      startDoc: (this.currentPage - 1) * this.itemsPerPage || 0,
    },
    searchQuery.catalog_query ? { query: searchQuery.catalog_query as string, } : {});

    this.promise.emit(true);
    this.requestSubscriber = this.searchService
      .search(query)
      .pipe(
        finalize(() => {
          this.promise.emit(false);
          this.clearRequestSubscriber();
        }))
      .subscribe((response) => {
        this.itemCount = response.count;
        this.items = response.items;
      });
  }

  private clearRequestSubscriber(): void {
    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }
}
