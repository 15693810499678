import { downgradeComponent } from '@angular/upgrade/static';
import { HelpTextComponent as HelpTextAdmin } from 'modules/admin/help-text/view/help-text.component';
import { HelpTextComponent as HelpTextUser } from 'modules/help-texts/view/help-text.component';


declare const angular: angular.IAngularStatic;

export default angular
  .module('kmi.lms.core')
  .directive('helpTextComponent', downgradeComponent({ component: HelpTextAdmin }))
  .directive('helpText', downgradeComponent({ component: HelpTextUser }));
