import { Injectable, OnDestroy } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { RawParams } from '@uirouter/core/lib/params/interface';
import { StateDeclaration } from '@uirouter/core/lib/state/interface';
import { NavigationService } from 'core/navigation';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { Subject, Unsubscribable } from 'rxjs';


@Injectable({
  providedIn: 'platform'
})
export class UrlParamService<T = string | ReadonlyArray<string>> implements OnDestroy {
  urlParams: IUrlParams<T>;

  private urlSubscriber: UrlParamHandler;
  private subscribers: Unsubscribable[] = [];
  private subject = new Subject<IUrlParams<T>>();

  constructor(
    private urlService: UrlService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private navigationService: NavigationService,
  ) {}

  ngOnDestroy() {
    if (this.urlSubscriber) {
      this.urlSubscriber();
      this.urlSubscriber = null;
      this.urlParams = null;
    }

    this.subscribers?.forEach(s => s.unsubscribe());
    this.subject.complete();
    this.subscribers = null;
  }

  subscribe(fn: (v: IUrlParams<T>) => void): Unsubscribable {
    const subscriber = this.subject.subscribe(fn);

    if (!this.urlSubscriber) {
      this.urlSubscriber = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
      this.ngOnUrlChange();
    } else {
      fn(this.urlParams);
    }

    this.subscribers.push(subscriber);

    return subscriber;
  }

  getState(name: string): StateDeclaration {
    return this.navigationService.getState(name);
  }

  navigate(params: RawParams) {
    const filters = Object.assign({}, this.urlParams, params);

    this.stateService.go(this.activeState.current, filters);
  }

  replace(params: RawParams) {
    const filters = Object.assign({}, this.urlParams, params);

    this.stateService.go(this.activeState.current, filters, { location: 'replace' });
  }

  private ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity) as IUrlParams<T>;

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.subject.next(params);
    }
  }
}
