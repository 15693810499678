import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ICertificationInformation } from '../models/certification-information.model';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


enum UserVolunteerCertificationInformationEndpoints {
  GET = '/api-volunteers/users/:userId/agreements/info/',
}


@Injectable()
export class UserVolunteerCertificationInformationService {
  constructor(protected http: HttpClient) { }

  get(userId: number, throwHttpErrors = false): Observable<ICertificationInformation> {
    return this.http.get<ICertificationInformation>(
      ElmsUtils.formatUrl(UserVolunteerCertificationInformationEndpoints.GET, { userId: userId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }
}
