import { downgradeComponent } from '@angular/upgrade/static';
import {
  UserAchievementsScalesComponent
} from 'modules/user-achievements/components/user-achievements-scales.component';


declare const angular: angular.IAngularStatic;

export default angular.module('kmi.lms.core')
  .directive(
    UserAchievementsScalesComponent.selector,
    downgradeComponent({ component: UserAchievementsScalesComponent }) as angular.IDirectiveFactory
  );
