<div *ngIf="filter.visible && filter.items.length > 0">
  <ngb-accordion *ngIf="!fullScreen"
                 #acc="ngbAccordion"
                 class="search-filter-group"
                 [activeIds]="isOpen?'panel-'+filter.term:''">
    <ngb-panel [id]="'panel-'+filter.term ">
      <ng-template ngbPanelTitle>
        <span class="pull-right fa fa-fw"
              [ngClass]="[acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
        {{filter.label}}
      </ng-template>
      <ng-template ngbPanelContent>
        <div *ngFor="let item of filter.items">
          <form #dateForm="ngForm" name="dateForm" class="form-group" [ngClass]="{'has-error':dateInput.invalid}">
            <label>{{item.text}}</label>
            <div class="input-group">
              <datepicker [id]="'dateInput'" name="dateInput" placeholder="MM/DD/YYYY"
                          (ngModelChange)="dateChanged($event, item);"
                          [(ngModel)]="item.value" [useTime]="false" [showWeeks]="false"
                          #dateInput="ngModel" #datePicker='datepicker'></datepicker>
            </div>
            <p class="help-block"
               [hidden]="!(dateInput.invalid && dateInput.dirty)">
              <span [hidden]="!dateInput.errors?.parse">The date format is mm/dd/yyyy.</span>
            </p>
          </form>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div *ngIf="fullScreen" class="">

    <ul class="content-list">
      <li class="content-list-item search-item"
          [ngClass]="{'search-item-selected':item.selected}" *ngFor="let item of filter.items">

        <div class="date-item">
          <form #dateForm='ngForm' name="dateForm" class="form-group no-margin-bottom"
                [ngClass]="{'has-error': dateInput.invalid}">
            <label>{{item.text}}</label>
            <div class="input-group">
              <datepicker [id]="'dateInput'" name="dateInput" placeholder="MM/DD/YYYY"
                          (ngModelChange)="dateChanged($event, item);"
                          [(ngModel)]="item.value" [useTime]="false" [showWeeks]="false"
                          #dateInput="ngModel" #datePicker='datepicker'></datepicker>
            </div>
            <p class="help-block"
               [hidden]="!(dateInput.invalid && dateInput.dirty)">
              <span [hidden]="!dateInput.errors?.parse">The date format is mm/dd/yyyy.</span>
            </p>
          </form>
        </div>
      </li>
    </ul>
  </div>
</div>
