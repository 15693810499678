import { FactoryProvider } from '@angular/core';


export type markdownRender = (value: string) => string | null;

export class MarkdownRenderService {
  private md: markdownRender;

  constructor(md: markdownRender) {
    this.md = md;
  }

  render(value: string): string {
    return this.md(value);
  }
}

export function markdownRenderServiceFactory(injector: angular.auto.IInjectorService): MarkdownRenderService {
  const render = injector.get<markdownRender>('markdownRender');

  return new MarkdownRenderService(render);
}

export const markdownRenderServiceProvider: FactoryProvider = {
  provide: MarkdownRenderService,
  useFactory: markdownRenderServiceFactory,
  deps: ['$injector']
};

// TODO WE need to find the way how to properly use the markdown-it-katex, markdown-it-sup, markdown-it-sub
// import { Injectable } from '@angular/core';
// import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
// import markdownIt from 'markdown-it';
// import mdAttr from 'markdown-it-attrs';
// import mdAnchor from 'markdown-it-anchor/dist/markdownItAnchor';
// import mdKatex from '@iktakahiro/markdown-it-katex';
// import mdSup from 'markdown-it-sup';
// import mdSub from 'markdown-it-sub';
// import _ from 'lodash';
// import Renderer, { RenderRule } from 'markdown-it/lib/renderer';
// import Token from 'markdown-it/lib/token';
//
//
// const defaultRender: RenderRule = (tokens, idx, options, _, self) => {
//   return self.renderToken(tokens, idx, options);
// };
//
//
// @Injectable()
// export class MarkdownRenderService {
//   private md: markdownIt;
//   private renderer: RenderRule;
//
//   constructor(private globalConfig: GlobalConfig) {
//     this.md = markdownIt({
//       html: !globalConfig.markdown.sanitize,
//       linkify: true,
//       typographer: true
//     })
//       .disable('smartquotes')
//       .use(mdAttr)
//       .use(mdAnchor, {});
//
//     if (globalConfig.settings.katexMessageVisible) {
//       this.md.use(mdKatex)
//         .use(mdSup)
//         .use(mdSub);
//     }
//
//     // Remember old renderer, if overridden, or proxy to default renderer
//     this.renderer = this.md.renderer.rules.link_open || defaultRender;
//
//     // Override all links to open it in a new window
//     this.md.renderer.rules.link_open = this.linkOpen;
//   }
//
//   render(value: string): string {
//     return this.md.render(value);
//   }
//
//   private linkOpen(tokens: Token[], idx: number, options: markdownIt.Options, env: any, self: Renderer) {
//     // If you are sure other plugins can't add `target` - drop check below
//     const aIndex = tokens[idx].attrIndex('target');
//     const hrefAttrIndex = tokens[idx].attrIndex('href');
//
//     if (hrefAttrIndex > -1) {
//       if (_.startsWith(tokens[idx].attrs[hrefAttrIndex][1], '#')) {
//         return this.renderer(tokens, idx, options, env, self);
//       }
//     }
//
//     if (aIndex < 0) {
//       tokens[idx].attrPush(['target', '_blank']); // add new attribute
//     } else {
//       tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
//     }
//
//     // pass token to default renderer.
//     return this.renderer(tokens, idx, options, env, self);
//   }
// }
