import { Injectable } from '@angular/core';
import { ObjectFilterService } from 'app/deprecated/search/services/filters-old/object-filter.service.ajs-upgraded';
import { ISearchFilterOptionsUpgraded, ISearchFilterUpgraded } from '../../models/search-filters.models';
import { ApprovalFilterService } from './approval-filter.service.ajs-upgraded';
import { CertificateFilterService } from './certificate-filter.service.ajs-upgraded';
import { CollectionTypesFilterService } from './collection-types-filtering.service.ajs-upgraded';
import { CourseFilterService } from './course-filter.service.ajs-upgraded';
import { LabelFilterService } from './label-filter.service.ajs-upgraded';
import { LSFilterService } from './ls-filter.service.ajs-upgraded';
import { BaseFilterService } from './search-filtering.service.ajs-upgraded';
import { UserFilterService } from './user-filter.service.ajs-upgraded';


export type ISearchFiltersMap = { get: (options?: Partial<ISearchFilterOptionsUpgraded>) => ISearchFilterUpgraded };


// TODO Since all JS Search Filters are not aligned the same injection style this class is needed.
// TODO please remove this class once all Search Filters are implemented and aligned with the same injection style.
@Injectable()
export class SearchFiltersMapperUpgradedService {
  private readonly filtersMap: Record<string, ISearchFiltersMap> = {
    'objectFilter': { get: () => this.getObjectFilter() },
    'tagFilter': { get: (o) => this.getTagFilter(o) },
    'courseFormatFilter': { get: (o) => this.getCourseFormatFilter(o) },
    'courseLabelFilter': { get: () => this.getCourseLabelFilter() },
    'creditTypeFilter': { get: (o) => this.getCreditTypeFilter(o) },
    'userGroupsFilter': { get: () => this.getUserGroupsFilter() },
    'subGroupsFilter': { get: (o) => this.getSubGroupsFilter(o) },
    'userRolesFilter': { get: (o) => this.getUserRolesFilter(o) },
    'courseCreatorFilter': { get: (o) => this.getCourseCreatorFilter(o) },
    'organizationFilter': { get: (o) => this.getOrganizationFilter(o) },
    'myOrganizationFilter': { get: (o) => this.getMyOrganizationFilter(o) },
    'organizationUserFilter': { get: () => this.getOrganizationUserFilter() },
    'certificatesFilter': { get: () => this.getCertificatesFilter() },
    'courseCertificatesFilter': { get: () => this.getCourseCertificatesFilter() },
    'approvalStatusFilter': { get: (o) => this.getApprovalStatusFilter(o) },
    'trainingCategoriesFilter': { get: (o) => this.getTrainingCategoriesFilter(o) },
    'nationalCpFilter': { get: (o) => this.getNationalCpFilter(o) },
    'myLsFilter': { get: (o) => this.getMyLsFilter(o) },
  };

  constructor(
    private filterService: BaseFilterService,
    private objectFilter: ObjectFilterService,
    private labelFilter: LabelFilterService,
    private userFilter: UserFilterService,
    private certificateFilter: CertificateFilterService,
    private courseFilter: CourseFilterService,
    private approvalFilter: ApprovalFilterService,
    private lsFilter: LSFilterService,
    private collectionTypesFilter: CollectionTypesFilterService,
  ) { }

  get(name: string, options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    const filter = this.filtersMap[name];

    return filter?.get(options);
  }

  private getObjectFilter(): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.objectFilter);
  }

  private getTagFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return new this.filterService.TagFilter(options);
  }

  private getCourseFormatFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return new this.filterService.CourseFormatFilter(options);
  }

  private getCourseLabelFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    const filter = this.filterService.getFilter(this.labelFilter);

    if (options?.label) {
      filter.label = options.label;
    }

    return filter;
  }

  private getCreditTypeFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return new this.filterService.CreditTypeFilter(options);
  }

  private getUserGroupsFilter(): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.userFilter.getGroupFilter());
  }

  private getSubGroupsFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.userFilter.getIncludeSubgroupsFilter(options));
  }

  private getUserRolesFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.userFilter.getRoleFilter(options));
  }

  private getCourseCreatorFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.courseFilter.getCourseCreatorFilter(options));
  }

  private getOrganizationFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.courseFilter.getOrganizationFilterConfig(options));
  }

  private getMyOrganizationFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.courseFilter.getMyOrganizationFilter(options));
  }

  private getOrganizationUserFilter(): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.courseFilter.getOrganizationUserFilter());
  }

  private getCertificatesFilter(): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.certificateFilter.getMyCertificatesFilterConfig());
  }

  private getCourseCertificatesFilter(): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.certificateFilter.getCertificateCoursesFilterConfig());
  }

  private getApprovalStatusFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.approvalFilter.getApprovalFilterConfig(options));
  }

  private getMyLsFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.lsFilter.getMyLsFilterConfig(options));
  }

  private getTrainingCategoriesFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.collectionTypesFilter.getTrainingCategoriesFilterConfig(options));
  }

  private getNationalCpFilter(options?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterUpgraded {
    return this.filterService.getFilter(this.courseFilter.getNationalCpFilter(options));
  }
}
