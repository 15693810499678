import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, NgZone, Type } from '@angular/core';
import { UIRouter, UrlService } from '@uirouter/core';
import { AppEnvironmentManger } from './lms-environment/environment-manager';
import { ThemeManager } from './lms-environment/theme-manager';
import { configureThirdParties } from 'ajs/third-parties/manager';
import { environment } from './environments/environment';


export default function runLMS<T>(module: Type<T>) {
  const environmentManger = new AppEnvironmentManger();

  environmentManger.loadUserData()
    .then(async (user) => {
      const envSetting = await environmentManger.loadEnvironment();

      envSetting.elmsEnvironment.user = user;

      return envSetting;
    })
    .then(envSetting => {
      configureThirdParties(envSetting);

      const themeManager = new ThemeManager(envSetting);

      return themeManager.setupTheme();
    })
    .then(() => {
      // setAngularJSGlobal(angular);
      if (environment.production) {
        enableProdMode();
      }

      platformBrowserDynamic().bootstrapModule(module, { preserveWhitespaces: true })
        .then((platformRef) => {
          const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

          // Instruct UIRouter to listen to URL changes
          function startUIRouter() {
            urlService.listen();
            urlService.sync();
          }

          // platformRef.injector.get<NgZone>NgZone.run(startUIRouter);
          platformRef.injector.get(NgZone).run(startUIRouter);
        });
    });
}
