import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { ErrorsService } from 'core/services/errors.service';
import { PASS_HTTP_ERRORS_TOKEN } from './http-request-error-handler.interceptor';


const HTTP_ERROR_CODES = [
  401, // HttpStatusCode.Unauthorized,
  403, // HttpStatusCode.Forbidden,
  404, // HttpStatusCode.NotFound,
  408, // HttpStatusCode.RequestTimeout,
  -1
];


@Injectable()
export class HttpRequestStatusInterceptor implements HttpInterceptor {
  private requests = 0;
  private errors = 0;

  constructor(private notificationService: NotificationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const passHttpErrors = request.context.get(PASS_HTTP_ERRORS_TOKEN);

    this.requests++;

    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => this.handleError(e, passHttpErrors)),
      finalize(() => this.handleFinalize())
    );
  }

  private handleError(error: HttpErrorResponse, passHttpErrors: boolean) {
    /*
    * from AngularJS we handle `rejection.config.timeout` that's used for cancel requests.
    * And since interceptors cannot handle such requests, and we avoid canceled requests in the logic,
    * we're taking away the usage of `rejection.config.timeout` from the `if` statement below.
    */
    if (!passHttpErrors && error.status > 0 && !HTTP_ERROR_CODES.includes(error.status)) {
      this.errors++;
    }

    this.updateStatus();

    return throwError(() => error);
  }

  private handleFinalize() {
    this.requests--;
    this.updateStatus();
  }

  private updateStatus() {
    if (this.requests === 0 && this.errors > 0) {
      this.errors = 0;
      this.notificationService.error(ErrorsService.defaultMessage, 10000);
    }
  }
}
