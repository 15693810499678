import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';


@Injectable()
export class HttpCommonHeaderInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private globalConfig: GlobalConfig) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hasConfigs = { cacheByPass: request.params.has('cacheByPass') };
    let params = request.params;
    let headers = request.headers
      .append('Accept', ['application/json', 'text/plain', '*/*'])
      .append('X-CSRFToken', this.cookieService.get('csrftoken'))
      .append('Cache-Control', 'no-cache')
      .append('Pragma', 'no-cache');

    if (this.globalConfig.base && !request.headers.has('Lms-BasePath')) {
      headers = headers.append('Lms-BasePath', this.globalConfig.base);
    }

    if (this.globalConfig.settings.view && !request.headers.has('Lms-ApplicationView')) {
      headers = headers.append('Lms-ApplicationView', this.globalConfig.settings.view);
    }

    if (this.globalConfig.settings.view === 'admin' || hasConfigs.cacheByPass) {
      headers = headers.append('X-Proxy-Cache', 'BYPASS');

      if (hasConfigs.cacheByPass) {
        params = request.params.delete('cacheByPass');
      }
    }

    return next.handle(request.clone({ headers, params }));
  }
}
