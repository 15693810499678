export { RegistrationErrors, RegistrationErrorsTypes } from './registration-errors.types';
export { CourseFormats, FormatType, LocationType, CourseTypes, ApprovalStatus } from './course.types';
export {
  ActiveRegistrationStatuses,
  CompletedRegistrationStatuses,
  DeclinedRegistrationStatuses,
  IncompleteRegistrationStatuses,
  TerminalRegistrationStatuses,
  StrictCompletedRegistrationStatuses,
  RegistrationStatuses,
  InProgressRegistrationStatuses,
  NotStartedRegistrationStatuses,
  WithdrawnRegistrationStatuses,
  RegistrationTypes
} from './registration.types';

export type InjectorService = angular.auto.IInjectorService;

export class NotImplementedError extends Error {
  constructor(message = 'Not yet implemented!') {
    super(message);
  }
}
