import { FactoryProvider } from '@angular/core';
import {
  ISearchFilterOptionsUpgraded,
  ISearchFilterOptionsService
} from 'app/deprecated/search/models/search-filters.models';


export abstract class LSFilterService {
  abstract getMyLsFilterConfig(config?: Partial<ISearchFilterOptionsUpgraded>): ISearchFilterOptionsService;
}

export function searchLSFilterFactory(injector: angular.auto.IInjectorService): LSFilterService {
  return injector.get<LSFilterService>('searchFilterLearningSeries');
}

export const searchLSFilterProvider: FactoryProvider = {
  provide: LSFilterService,
  useFactory: searchLSFilterFactory,
  deps: ['$injector']
};
