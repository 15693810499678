import { NotImplementedError } from 'core/types/index';


export enum RegistrationStatuses {
  pending = 1,
  approved = 2,
  declined = 3,
  inProgress = 4,
  completed = 5,
  withdrawn = 6,
  waitList = 7,
  preAssessmentPending = 8,
  assessmentPending = 9,
  notStarted = 10,
  registrationPending = 17,
  postAssessmentSubmitted = 19,
  evaluationPending = 20,
  passed = 40,
  notPassed = 41,
  expired = 42
}

export const NotStartedRegistrationStatuses = [
  RegistrationStatuses.notStarted, RegistrationStatuses.pending, RegistrationStatuses.approved,
  RegistrationStatuses.waitList, RegistrationStatuses.declined, RegistrationStatuses.withdrawn,
  RegistrationStatuses.registrationPending
];

export const InProgressRegistrationStatuses = [
  RegistrationStatuses.inProgress, RegistrationStatuses.preAssessmentPending,
  RegistrationStatuses.assessmentPending, RegistrationStatuses.postAssessmentSubmitted,
  RegistrationStatuses.evaluationPending
];

export const ActiveRegistrationStatuses = [
  RegistrationStatuses.notStarted, RegistrationStatuses.pending, RegistrationStatuses.approved,
  RegistrationStatuses.inProgress, RegistrationStatuses.registrationPending, RegistrationStatuses.waitList,
  RegistrationStatuses.preAssessmentPending, RegistrationStatuses.assessmentPending,
  RegistrationStatuses.postAssessmentSubmitted, RegistrationStatuses.evaluationPending
];

export const CompletedRegistrationStatuses = [
  RegistrationStatuses.completed, RegistrationStatuses.passed, RegistrationStatuses.notPassed
];

export const StrictCompletedRegistrationStatuses = [
  RegistrationStatuses.completed, RegistrationStatuses.passed
];

export const IncompleteRegistrationStatuses = [
  RegistrationStatuses.pending,
  RegistrationStatuses.registrationPending,
  RegistrationStatuses.approved,
  RegistrationStatuses.inProgress,
  RegistrationStatuses.waitList,
  RegistrationStatuses.preAssessmentPending,
  RegistrationStatuses.assessmentPending,
  RegistrationStatuses.notStarted,
  RegistrationStatuses.postAssessmentSubmitted,
  RegistrationStatuses.evaluationPending,
  RegistrationStatuses.notPassed
];
export const DeclinedRegistrationStatuses = [
  RegistrationStatuses.declined, RegistrationStatuses.withdrawn
];
export const WithdrawnRegistrationStatuses = [
  RegistrationStatuses.declined, RegistrationStatuses.withdrawn, RegistrationStatuses.expired
];
export const TerminalRegistrationStatuses = [
  RegistrationStatuses.completed, RegistrationStatuses.passed, RegistrationStatuses.notPassed,
  RegistrationStatuses.expired, RegistrationStatuses.withdrawn
];

export class RegistrationTypes {
  static notStartedStatuses = NotStartedRegistrationStatuses;
  static inProgressStatuses = InProgressRegistrationStatuses;
  static activeStatuses = ActiveRegistrationStatuses;
  static completedStatuses = CompletedRegistrationStatuses;
  static strictCompletedStatuses = StrictCompletedRegistrationStatuses;
  static incompleteStatuses = IncompleteRegistrationStatuses;
  static declinedStatuses = DeclinedRegistrationStatuses;
  static withdrawnStatuses = WithdrawnRegistrationStatuses;
  static terminalStatuses = TerminalRegistrationStatuses;

  public static populateStatuses() {
    throw new NotImplementedError();
  }
}
