import { downgradeInjectable } from '@angular/upgrade/static';
import {
  UserVolunteerAgreementService,
} from 'projects/irs/modules/user-volunteer-agreements/services/user-volunteer-agreement.service';
import {
  UserVolunteerCertificationInformationService,
} from 'projects/irs/modules/user-volunteer-agreements/services/user-volunteer-certification-information.service';
import {
  VolunteerAgreementService,
} from 'projects/irs/modules/volunteer-agreements/services/volunteer-agreement.service';


declare const angular: angular.IAngularStatic;


export default angular
  .module('kmi.lms.core')
  .factory('userVolunteerAgreementService', downgradeInjectable(UserVolunteerAgreementService))
  .factory('userVolunteerCertificationInformationService',
    downgradeInjectable(UserVolunteerCertificationInformationService))
  .factory('volunteerAgreementService', downgradeInjectable(VolunteerAgreementService));
