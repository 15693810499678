import { downgradeInjectable } from '@angular/upgrade/static';
import { GeoService } from 'core/services/geo/geo.service';


declare const angular: angular.IAngularStatic;


export default angular
  .module('kmi.lms.core')
  .factory('geoService', downgradeInjectable(GeoService));
