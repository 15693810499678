import { downgradeInjectable } from '@angular/upgrade/static';
import { ApprovalStatus } from 'core/types';
import { ApprovalStatusService } from 'modules/course/services/approval-status.service';


declare const angular: angular.IAngularStatic;

export default angular.module('kmi.lms.core')
  .factory('approvalStatusDowngraded', downgradeInjectable(ApprovalStatusService))
  .factory('approvalStatus', ($injector) => {
    const service = $injector.get('approvalStatusDowngraded') as ApprovalStatusService;

    return {
      approved: ApprovalStatus.approved,
      declined: ApprovalStatus.declined,
      incomplete: ApprovalStatus.incomplete,
      moreInformationRequested: ApprovalStatus.moreInformationRequested,
      readyForApproval: ApprovalStatus.readyForApproval,

      getStatusName: (statusId: number) => {
        return service.getStatusName(statusId);
      },
      toList() {
        return service.toList();
      }
    };
  });
