import { Injectable } from '@angular/core';
import { ICourse } from 'modules/course/common/models/course.model';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';


@Injectable()
export class AiHelperService {
  private currentSiteSetting: ISiteSettings;

  constructor(
    private siteSettingsService: SiteSettingsService
  ) {}

  public async getCourseThumbnailAiPrompt(course: ICourse): Promise<string> {
    const promptParts = [];

    await this.loadSiteSetting();

    promptParts.push(`There is a LMS online portal ${this.currentSiteSetting.siteShortName}.`);

    if (this.currentSiteSetting.description) {
      promptParts.push(this.currentSiteSetting.description);
    }

    promptParts.push('I need a thumbnail image for a course.');
    promptParts.push(`Course name is "${course.name}".`);
    promptParts.push(`Course description is "${course.description}".`);

    if (course.subjectAreas.length) {
      promptParts.push(`Course subject areas are: ${course.subjectAreas.map(area => area.name).join(', ')}.`);
    }

    if (course.targetAudiences.length) {
      promptParts.push(`Course Target audiences: ${course.targetAudiences.map(audience => audience.name).join(', ')}.`);
    }

    promptParts.push('The image should be in a 16:9 aspect ratio.');
    promptParts.push(
      'The image should be visually appealing and relevant to the course content. ' +
      'The image should be colorful, engaging and without text.'
    );
    promptParts.push(
      'The image should be professional and high-quality. ' +
      'The image should be suitable for a wide audience.'
    );

    return promptParts.join(' ');
  }

  private async loadSiteSetting() {
    this.currentSiteSetting = await this.siteSettingsService.getCurrent();
  }
}
