import { Component } from '@angular/core';
import { UrlParamService } from 'core/navigation';
import { SearchComponent } from 'modules/search/components/search.component';
import { userSearchBMSFilterProviders } from '../services';
import { CommonModule } from '@angular/common';
import { SearchCommonModule } from 'modules/search/common/search-common.module';


@Component({
  standalone: true,
  selector: 'search-bms-component',
  // absolute URLs are not supported in AOT mode
  // https://github.com/angular/angular-cli/issues/20162#issuecomment-788909341
  templateUrl: '../../../../../app/modules/search/components/search.component.html',
  imports: [CommonModule, SearchCommonModule],
  providers: [UrlParamService, ...userSearchBMSFilterProviders],
})
export class SearchBMSComponent extends SearchComponent { }
