import { downgradeComponent } from '@angular/upgrade/static';
import { SearchFilterViewDepComponent } from 'app/deprecated/search/components/filtering/search-filter-view.component';
import {
  SelectedSearchFiltersDepComponent
} from 'app/deprecated/search/components/filtering/selected-search-filters.component';
import {
  SearchMobileActionPanelDepComponent
} from 'app/deprecated/search/components/search-mobile-action-panel.component';
import { SearchResultDepComponent } from 'app/deprecated/search/components/search-result.component';


declare const angular: angular.IAngularStatic;

export default angular.module('kmi.lms.core')
  .directive(
    SearchFilterViewDepComponent.selector,
    downgradeComponent({ component: SearchFilterViewDepComponent }) as angular.IDirectiveFactory)
  .directive(
    SelectedSearchFiltersDepComponent.selector,
    downgradeComponent({ component: SelectedSearchFiltersDepComponent }) as angular.IDirectiveFactory)
  .directive(
    SearchMobileActionPanelDepComponent.selector,
    downgradeComponent({ component: SearchMobileActionPanelDepComponent }) as angular.IDirectiveFactory)
  .directive(
    SearchResultDepComponent.selector,
    downgradeComponent({ component: SearchResultDepComponent }) as angular.IDirectiveFactory);

