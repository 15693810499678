import { FactoryProvider } from '@angular/core';
import { JSONData } from 'core/models';
import { ISearchErrorResponse, ISearchItem } from 'modules/search/models/search.models';
import { ISearchServiceOptions } from '../models/search.models';


export interface ISearchResponseUpgraded<T> {
  data: {
    count: number;
    error: ISearchErrorResponse | null;
    items: T[];
  }
}

export interface SearchServiceUpgraded<T> {
  search: (params: JSONData) => Promise<ISearchResponseUpgraded<T>>;
  getSearchExportLink: () => string;
  getPage: (page: number) => Promise<JSONData>;
  searchNext: () => Promise<JSONData>;
  getObjectTypes: () => Promise<JSONData>
  getExtendedObjectTypes: () => Promise<JSONData>;
  getSuggestions: (params: JSONData) => Promise<JSONData>;
  getUserSuggestions: () => { run: (params: JSONData) => Promise<JSONData>, cancel: () => void };
}

export abstract class SearchUpgradedProvider {
  abstract get<T extends ISearchItem>(options: ISearchServiceOptions): SearchServiceUpgraded<T>;
}

export function searchServiceProviderFactory(injector: angular.auto.IInjectorService): SearchUpgradedProvider {
  return injector.get<SearchUpgradedProvider>('searchService');
}

export const searchServiceProvider: FactoryProvider = {
  provide: SearchUpgradedProvider,
  useFactory: searchServiceProviderFactory,
  deps: ['$injector']
};
