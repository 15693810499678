import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpErrorResponse, HttpContextToken
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError, EMPTY } from 'rxjs';
import { ElmsAuthService } from 'ajs/modules/app/authorization/elms-auth-service';


export const IGNORE_AUTHORIZATION_TOKEN = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpAuthenticationInterceptor implements HttpInterceptor {
  constructor(private elmsAuthService: ElmsAuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoredAuthorization = request.context.get(IGNORE_AUTHORIZATION_TOKEN);
    let headers = request.headers;

    if (this.elmsAuthService.authToken && !ignoredAuthorization) {
      headers = headers.append('ElmsAuthorization', 'Token ' + this.elmsAuthService.authToken);
    }

    return next.handle(request.clone({ headers })).pipe(
      tap((e: HttpEvent<any>) => this.eventHandler(e)),
      catchError((e: HttpErrorResponse) => this.handleError(e))
    );
  }

  private eventHandler(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response) {
      this.handleResponse(event);
    }
  }

  private handleResponse(response: HttpResponse<any>) {
    if (response.headers.has('authorization')) {
      this.elmsAuthService.authToken = response.headers.get('authorization');
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 403 && error.error === 'err_authentication_ticket_compliance') {
      this.elmsAuthService.showAuthTicketComplianceError();

      return EMPTY;
    }

    return throwError(() => error);
  }
}
