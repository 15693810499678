import { downgradeComponent } from '@angular/upgrade/static';
import { NavigationMenuComponent } from 'modules/navigation/components/navigation-menu.component';


declare const angular: angular.IAngularStatic;

export default angular.module('kmi.lms.core')
  .directive(
    NavigationMenuComponent.selector,
    downgradeComponent({ component: NavigationMenuComponent }) as angular.IDirectiveFactory
  );
