export function getMainMenuItems(defaultPortal, basePath) {
  if (defaultPortal) {
    return [
      {
        'name': 'Home',
        'href': 'https://{{externalDomain}}/',
        'icon': 'ant-icon ant-home',
        'external': true,
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !!currentUser.get().bmsUserId;
          },
          availableFor: ['regularUser', 'admin'],
        }
      },
      {
        'name': 'Training Hub',
        'stateName': 'main.home',
        'icon': 'ant-icon ant-training-hub',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return currentUser.get().bmsUserId;
          },
          availableFor: ['regularUser', 'admin']
        }
      },
      {
        'name': 'Home',
        'stateName': 'main.home',
        'icon': 'ant-icon ant-training-hub',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !currentUser.get().bmsUserId;
          },
          availableFor: ['regularUser', 'admin'],
        }
      },
      {
        'name': 'My Profile',
        'stateName': 'edit.account',
        'icon': 'ant-icon ant-profile',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !currentUser.get().bmsUserId;
          },
          availableFor: ['regularUser', 'admin'],
        }
      },
      {
        'name': 'Available Training ',
        'stateName': 'main.search',
        'stateParams': { type: '' },
        'icon': 'ant-icon ant-available-training',
        data: { 'availableFor': ['regularUser', 'admin'], }
      },
      {
        'name': 'My Training',
        'stateName': 'main.learning',
        'stateParams': { 'view': 'all', 'statuses': 'all', 'target': 'my_training' },
        'icon': 'ant-icon ant-my-training',
        data: { 'availableFor': ['regularUser', 'admin'], }
      },
      {
        'name': 'My Certifications',
        'stateName': 'main.learning',
        'stateParams': { 'view': 'certifications', 'target': 'certifications', 'statuses': 'all' },
        'icon': 'ant-icon ant-my-certification',
        data: { 'availableFor': ['regularUser', 'admin'] }
      },
      {
        'name': 'My Transcript',
        'stateName': 'main.learning',
        'stateParams': { 'view': 'all', 'statuses': 'completed', 'target': 'my_transcript' },
        'icon': 'ant-icon ant-my-transcript',
        data: { 'availableFor': ['regularUser', 'admin'], }
      },
      {
        'name': 'Calendar',
        'stateName': 'main.calendar',
        'icon': 'ant-icon ant-calendar',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !currentUser.get().anonymous;
          },
          availableFor: ['anonymous', 'regularUser', 'admin'],
        }
      },
      {
        'name': 'Discussions',
        'stateName': 'main.search',
        'stateParams': { type: 'discussion' },
        'icon': 'ant-icon ant-discussions',
        data: { 'availableFor': ['regularUser', 'admin'], }
      },
      {
        'name': 'Policies/Resources',
        'stateName': 'main.search',
        'stateParams': { type: 'resource', page: 1 },
        'icon': 'ant-icon ant-policies-resources',
        data: { 'availableFor': ['regularUser', 'admin'], }
      },
      {
        'name': 'Admin',
        'href': '/lms/admin',
        'icon': 'ant-icon ant-admin',
        data: { 'availableFor': ['admin'], }
      }
    ];
  } else {
    return [
      {
        'name': 'Home',
        'stateName': 'main.home',
        'icon': 'ant-icon ant-training-hub',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !currentUser.get().bmsUserId;
          },
          'availableFor': ['regularUser', 'admin'],
        }
      },
      {
        'name': 'My Profile',
        'stateName': 'edit.account',
        'icon': 'ant-icon ant-profile',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !currentUser.get().bmsUserId;
          },
          'availableFor': ['regularUser', 'admin'],
        }
      },
      {
        'name': 'Available Training ',
        'stateName': 'main.search',
        'stateParams': { type: '' },
        'icon': 'ant-icon ant-available-training',
        data: { 'availableFor': ['regularUser', 'admin'], }
      },
      {
        'name': 'My Training',
        'stateName': 'main.learning',
        'stateParams': { 'view': 'all', 'statuses': 'all', 'target': 'my_training' },
        data: { 'availableFor': ['regularUser', 'admin'], },
        'icon': 'ant-icon ant-my-training'
      },
      {
        'name': 'My Certifications',
        'stateName': 'main.learning',
        'stateParams': { 'view': 'certifications', 'target': 'certifications', 'statuses': 'all' },
        data: { 'availableFor': ['regularUser', 'admin'], },
        'icon': 'ant-icon ant-my-certification'
      },
      {
        'name': 'My Transcript',
        'stateName': 'main.learning',
        'stateParams': { 'view': 'all', 'statuses': 'completed', 'target': 'my_transcript' },
        data: { 'availableFor': ['regularUser', 'admin'], },
        'icon': 'ant-icon ant-my-transcript'
      },
      {
        'name': 'Discussions',
        'stateName': 'main.search',
        'stateParams': { type: 'discussion' },
        data: { 'availableFor': ['regularUser', 'admin'], },
        'icon': 'ant-icon ant-discussions'
      },
      {
        'name': 'Policies/Resources',
        'stateName': 'main.search',
        'stateParams': { type: 'resource', page: 1 },
        data: { 'availableFor': ['regularUser', 'admin'], },
        'icon': 'ant-icon ant-policies-resources'
      },
      {
        'name': 'Catalog',
        'stateName': 'main.search',
        'stateParams': { type: 'course' },
        'icon': 'fa-regular fa-rectangle-list',
        data: {
          dependsOn: function ($injector) {
            const currentUser = $injector.get('currentUser');

            return !currentUser.get().bmsUserId;
          }, 'availableFor': ['anonymous', 'regularUser', 'admin'],
        }
      },
      {
        'name': 'Calendar',
        'stateName': 'main.calendar',
        data: { availableFor: ['anonymous', 'regularUser', 'admin'], },
        'icon': 'ant-icon ant-calendar'
      },
      {
        'name': 'Admin',
        'href': ['/', basePath, '/admin'].join(''),
        data: { 'availableFor': ['admin'], },
        'icon': 'ant-icon ant-admin'
      }
    ];
  }
}
