<div *ngIf="filter.visible">
  <ngb-accordion *ngIf="!fullScreen"
                 class="truncate-labels"
                 [activeIds]="isOpen?'panel-'+filter.term:''"
                 #acc='ngbAccordion'>
    <ngb-panel [id]="'panel-'+filter.term">
      <ng-template ngbPanelTitle>
        <span class="pull-right fa fa-fw"
              [ngClass]="[acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
        {{filter.label}}
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="flex" *ngIf="filter.selectedItems.length > 0">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <a href="#" title="" (click)="openFilter()">{{filter.selectedItems.length}} items selected</a>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <button type="button" class="btn btn-success btn-block" *ngIf="filter.selectedItems.length <= 0"
                      (click)="openFilter()">Select {{filter.label}}</button>
              <button type="button" class="btn btn-danger btn-block" *ngIf="filter.selectedItems.length > 0"
                      (click)="filter.clearSelection()">Remove {{filter.label}}</button>
              <boolean-sub-filter-dep *ngIf="filter.subfilter!==undefined && filter.selectedItems.length > 0"
                                      [filter]="filter.subfilter"></boolean-sub-filter-dep>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div *ngIf="fullScreen" class="">
    <searchable-multi-choice-content-dep [filter]="filter"></searchable-multi-choice-content-dep>
  </div>
</div>
