
export enum CourseFormats {
  uploadedDocument = 101,
  SCORMCourse = 102,
  externalLink = 103,
  collection = 104,
  liveEvent = 106,
  exam = 108,
  videoCourse = 109,
  survey = 115,
  certification = 117,
  googleDrive = 118,
  zoomMeeting = 119,
  googleMeeting = 120,
  office365 = 121,
  trainingCategory = 123,
  msTeamsMeeting = 124,
  doxyMeMeeting = 122,
  trainingHistory = 125,
  webExMeeting = 126,
  goToMeeting = 127,
  examTrack = 128,
  article = 129,
  SCORMCourse2004 = 130,
  conference = 131,
  conferenceSession = 132
}

export enum FormatType {
  online = 1,
  liveEvent = 2,
  physicalCarrier = 3,
  collection = 4,
  conference = 5,
  conferenceSession = 6,
  exercise = 7
}

export enum ApprovalStatus {
  readyForApproval = 1,
  approved = 2,
  declined = 3,
  moreInformationRequested = 4,
  incomplete = 5
}

export enum LocationType {
  inPerson = 1,
  virtual = 2,
  inPersonAndVirtual = 3
}

export class CourseTypes {
  static format = CourseFormats;
  static formatType = FormatType;
  static locationType = LocationType;
  static approvalStatus = ApprovalStatus;

  static uploadContentFormats = [
    CourseFormats.SCORMCourse, CourseFormats.SCORMCourse2004, CourseFormats.videoCourse, CourseFormats.uploadedDocument
  ];

  static payLaterCourses = [
    CourseFormats.liveEvent, CourseFormats.zoomMeeting, CourseFormats.googleMeeting, CourseFormats.doxyMeMeeting,
    CourseFormats.msTeamsMeeting, CourseFormats.webExMeeting, CourseFormats.goToMeeting
  ];

  static supportedCourseFormats = [
    1, 2, 3, 4, 5, 6, 7, 8
  ];
}
