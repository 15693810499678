import _ from 'lodash';
import { BrowserController } from './browser-controller';


export interface EnvironmentSettings {
  globalConfig: any,
  elmsEnvironment: any
}

export class AppEnvironmentManger {
  protected globalConfig: any;
  private _browserController: BrowserController;
  private readonly statusesToRedirect = [500, 502, 504];

  constructor() {
    _.templateSettings = {
      interpolate: /\{\{(.+?)\}\}/g
    };

    this.globalConfig = (<any>window)._globalConfig;
    this._browserController = new BrowserController(this.globalConfig);
  }

  get endpoint() {
    return '/a/spa/environment?view=' + this.globalConfig.settings.view;
  }

  loadEnvironment(): Promise<EnvironmentSettings> {
    const envHeaders = new Headers();

    envHeaders.append('Content-Type', 'application/json');

    if (this._browserController.currentBasePath) {
      envHeaders.append('Lms-BasePath', this._browserController.currentBasePath);
    }

    const envRequest = new Request(this.endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: envHeaders,
    });

    return fetch(envRequest)
      .then(response => {
        if (!response.ok) {
          if (this.statusesToRedirect.includes(response.status)) {
            this._browserController.redirectTo502Error(response.status);
          }

          return Promise.reject(response);
        } else {
          return response.json()
            .then(value => {
              return this._processEnvData(value);
            });
        }
      });
  }

  async loadUserData(): Promise<any> {
    const envHeaders = new Headers();

    envHeaders.append('Content-Type', 'application/json');

    if (this._browserController.currentBasePath) {
      envHeaders.append('Lms-BasePath', this._browserController.currentBasePath);
    }

    const envRequest = new Request('/a/user/', {
      method: 'GET',
      mode: 'cors',
      headers: envHeaders,
    });

    const response = await fetch(envRequest);

    return response.json();
  }

  handleError(code: number) {
    this._browserController.redirectTo502Error(code);
  }

  protected _processEnvData(elmsEnvironment): EnvironmentSettings {
    (<any>window).elmsEnvironment = elmsEnvironment;

    this._browserController.adjustBasePath(elmsEnvironment.siteSettings.basePath);

    return { globalConfig: this.globalConfig, elmsEnvironment: elmsEnvironment };
  }
}


