import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { asyncScheduler, filter, Observable, observeOn, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCacheMapService } from 'core/services';
import { TTL } from 'core/services/http-cache/http-cache-model';
import { CacheFactory } from 'core/cache-factory.service.ajs-upgraded-provider';


export { TTL } from 'core/services/http-cache/http-cache-model';
export const CACHE_TTL_TOKEN = new HttpContextToken<number>(() => TTL.NO_CACHE);
export const CACHE_NAME_TOKEN = new HttpContextToken<string>(() => '__global');
export const FLUSH_CACHE_TOKEN = new HttpContextToken<string | string[]>(() => []);


@Injectable()
export class HttpCachingInterceptor implements HttpInterceptor {
  static pendingRequests: Record<string, Subject<HttpEvent<any>>> = {};

  constructor(
    private cache: HttpCacheMapService,
    private legacyCacheFactory: CacheFactory,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRequestCacheable(req)) {
      return next.handle(req).pipe(
        tap(() => this.clearCache(req))
      );
    }

    if (HttpCachingInterceptor.pendingRequests[req.urlWithParams]) {
      return HttpCachingInterceptor.pendingRequests[req.urlWithParams];
    }

    const cachedResponse = this.cache.get(req);

    if (cachedResponse !== null) {
      return of(cachedResponse).pipe(observeOn(asyncScheduler));
    }

    HttpCachingInterceptor.pendingRequests[req.urlWithParams] = new Subject<HttpEvent<any>>();

    return next.handle(req).pipe(
      filter(e => e.type === HttpEventType.Response),
      tap((e: HttpResponse<any>) => this.handleCacheableResponse(req, e)),
      tap(() => this.clearCache(req)),
      tap((e) => {
        HttpCachingInterceptor.pendingRequests[req.urlWithParams].next(e);
        HttpCachingInterceptor.pendingRequests[req.urlWithParams].complete();
        delete HttpCachingInterceptor.pendingRequests[req.urlWithParams];
      })
    );
  }

  private clearCache(req: HttpRequest<any>): void {
    let cacheNames: string | string[] = req.context.get(FLUSH_CACHE_TOKEN);

    if (typeof cacheNames === 'string') {
      cacheNames = [cacheNames as string];
    }

    cacheNames.forEach((cacheName) => {
      this.cache.clear(cacheName);
      const ajsCache = this.legacyCacheFactory.get(cacheName);

      if (ajsCache) {
        ajsCache.removeAll();
      }
    });
  }

  private handleCacheableResponse(req: HttpRequest<any>, event: HttpResponse<any>) {
    this.cache.set(req, event,
      {
        name: req.context.get(CACHE_NAME_TOKEN),
        ttl: req.context.get(CACHE_TTL_TOKEN),
      });
  }

  private isRequestCacheable(req: HttpRequest<any>): boolean {
    return (req.method === 'GET') && (req.context.get(CACHE_TTL_TOKEN) !== TTL.NO_CACHE);
  }
}
