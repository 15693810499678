import { downgradeComponent } from '@angular/upgrade/static';
import { BackButtonComponent } from 'core/navigation/components/back-button.component';


declare const angular: angular.IAngularStatic;


export default angular
  .module('kmi.lms.core')
  .directive(BackButtonComponent.selector, downgradeComponent({ component: BackButtonComponent }));
