<div *ngIf="filter.visible && filter.items.length > 0">
  <ngb-accordion *ngIf="!filter.appearance || filter.appearance === 'panel'"
                 class="truncate-labels"
                 [activeIds]="isOpen?'panel-'+filter.term:''"
                 #acc='ngbAccordion'>
    <ngb-panel [id]="'panel-'+filter.term">
      <ng-template ngbPanelTitle>
        <span class="pull-right fa fa-fw"
              [ngClass]="[acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
        {{filter.label}}
      </ng-template>
      <ng-template ngbPanelContent>
        <price-form-dep [filter]="filter" [status]="status"></price-form-dep>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div *ngIf="!fullScreen && filter.appearance === 'button'">
    <div class="btn-group btn-block" ngbDropdown [autoClose]="'outside'" #dropDown="ngbDropdown"
         (openChange)="status.isOpen=$event">
      <button id="SearchFilters" type="button" class="btn btn-default btn-block"
              ngbDropdownToggle>
        {{filter.label}}
        <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu class="full-width">
        <price-form-dep [filter]="filter" [status]="status" (applied)="dropDown.close()"></price-form-dep>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen">
    <price-form-dep [filter]="filter" [anyEvent]="fullScreen" class="display-block col-xs-12" style="padding-top:3px;"
                    [status]="status"></price-form-dep>
  </div>
</div>
