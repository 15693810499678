import { FactoryProvider } from '@angular/core';


export abstract class GlobalService {
  init: () => void;
  onAuthLoginRequired: () => void;
}

export function globalServiceFactory(injector: angular.auto.IInjectorService): GlobalService {
  return injector.get<GlobalService>('globalService');
}

export const globalServiceProvider: FactoryProvider = {
  provide: GlobalService,
  useFactory: globalServiceFactory,
  deps: ['$injector']
};
