/* "Barrel" of Core Services */
export { ErrorHandlerService } from './server-error-handler.service.ajs-upgraded-provider';
export { UrlVersionService } from './url-version.service.ajs-upgraded-provider';
export { MarkdownRenderService } from './markdown-render.service.ajs-upgraded-provider';
export { GlobalService } from './global.service.ajs-upgraded-provider';
export { HttpCacheMapService } from './http-cache/http-cache-map.service';
export { GeoService } from './geo/geo.service';
export { UserSettingsService, userSettingsServiceProvider } from './user-settings.service.ajs-upgraded-provider';
export { GTagService, gtagServiceProvider } from './gtag.service.ajs-upgraded-provider';
export { SecurityService,  securityServiceProvider } from './security.service.ajs-upgraded-provider';
export { HttpRequestParams } from './models/http.models';
export { RootScopeService, rootScopeServiceProvider } from './root-scope.service.ajs-upgraded-provider';
export { entityDataServiceProvider, EntityDataService } from './entity-data.service.ajs-upgraded-provider';
export { EnvironmentService } from './environment.service';
