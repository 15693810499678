<div *ngIf="filter.visible && filter.items.length">
  <div *ngIf="!fullScreen">
    <div class="panel panel-default">
      <div class="panel-heading no-border">
          <label class="use-hand-cursor no-margin-all w-100">
            <span class="panel-title h4">
              <input type="checkbox"
                     [checked]="filter.inverted?!filter.items[0].selected:filter.items[0].selected"
                     (change)="filter.items[0].selected=!filter.items[0].selected; filter.exec(filter.items[0].value, filter.term)">
              <span [innerHtml]="filter.label"></span>
            </span>
          </label>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen" class="">
    <label class="use-hand-cursor no-margin-top no-margin-bottom">
      <input type="checkbox"
             [checked]="filter.inverted?!filter.items[0].selected:filter.items[0].selected"
             (change)="filter.items[0].selected=!filter.items[0].selected; filter.exec(filter.items[0].value, filter.term)">
      <span [innerHtml]="filter.items[0].text"></span>
    </label>
  </div>
</div>
