import { UpgradeComponent } from '@angular/upgrade/static';
import { Directive, ElementRef, Injector } from '@angular/core';


@Directive({
  selector: 'lms-notifications'
})
export class LmsNotificationsDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('lmsNotifications', elementRef, injector);
  }
}
