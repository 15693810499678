import { downgradeComponent } from '@angular/upgrade/static';
import { AppComponent } from 'app/app.component';
import './modules/blogs/blog-post.module.ajs';
import './modules/resources/resource.service.ajs';
import './modules/instructors/instructors.service.ajs';
import './modules/blogs/blog-post.service.ajs';
import './modules/help-text/help-text.service.ajs';
import './modules/discussions/discussions.service.ajs';
import './modules/components/ngb-modal.service.ajs';
import './modules/global-dependencies';
import './modules/alerts/alert-messages.service.ajs';
import './modules/events/events.service.ajs';
import './services/geo.service.ajs';
import './modules/network/network.module.ajs';
import './modules/course/course.module.ajs';
import './modules/credits/accreditation-agency-info.ajs';
import './modules/search/search.module.ajs';
import './modules/quiz/quiz.module.ajs';
import './services/markdown.service.ajs';
import './modules/navigation/navigation.service.ajs';
import './modules/navigation/navigation-menu.component.ajs';
import './modules/user/achievements/achievements.module.ajs';
import './modules/components/common.module.ajs';
import './modules/login/login.module.ajs';
import './modules/integrations/saml-sso.service.ajs';
import './modules/user-volunteer-agreements/user-volunteer-agreement.service.ajs';
import './modules/course/course.service.ajs';


declare const angular: angular.IAngularStatic;

export const appModuleAjs = angular.module('elmsSpaApp')
  .directive(
    AppComponent.selector,
    downgradeComponent({ component: AppComponent }) as angular.IDirectiveFactory
  );
