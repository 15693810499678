import { FactoryProvider } from '@angular/core';


export interface ICacheObject{
  removeAll(): void;
}

export abstract class CacheFactory {
  abstract get(cacheId: string): ICacheObject;
}

export function cacheFactoryFactory(injector: angular.auto.IInjectorService): CacheFactory {
  return injector.get<CacheFactory>('$cacheFactory');
}

export const cacheFactoryProvider: FactoryProvider = {
  provide: CacheFactory,
  useFactory: cacheFactoryFactory,
  deps: ['$injector']
};
