<div *ngIf="filter.visible">
  <ngb-accordion *ngIf="!fullScreen"
                 #acc="ngbAccordion"
                 [activeIds]="isOpen?'panel-'+filter.term:''">
    <ngb-panel [id]="'panel-'+filter.term ">
      <ng-template ngbPanelTitle>
        <span class="pull-right fa fa-fw"
              [ngClass]="[ acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
        {{filter.label}}
      </ng-template>
      <ng-template ngbPanelContent>
        <input type="number" class="form-control" min="1" placeholder="Miles" [ngModelOptions]="{ updateOn: 'blur' }" #numberValue="ngModel"
               [(ngModel)]="filter.value" (keydown.enter)="blur($event)" (ngModelChange)="valueChanged(filter.value, filter.term)"/>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div *ngIf="fullScreen">
    <ul class="content-list">
      <li class="content-list-item search-item">
          <div class="form-group number-item">
              <input type="number" class="form-control" min="1" placeholder="Miles" [ngModelOptions]="{ updateOn: 'blur' }"
                     [(ngModel)]="filter.value" (keydown.enter)="valueChanged(filter.value, filter.term)" (change)="valueChanged(filter.value, filter.term)"/>
            </div>
      </li>
    </ul>
  </div>
</div>
