import { UpgradeComponent } from '@angular/upgrade/static';
import { Directive, ElementRef, Injector } from '@angular/core';


@Directive({
  selector: 'lms-main-container'
})
export class LmsMainContainerDirective extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
    super('lmsMainContainer', elementRef, injector);
  }
}
