import { Injectable, Type } from '@angular/core';
import { ObjectFilterService } from 'modules/search/common/services/filters/object-filter.service';
import { BaseSearchFilterService } from 'modules/search/common/services/filters/base-search.service';
import { CourseFormatFilterService } from 'modules/search/common/services/filters/course-format-filter.service';
import {
  CourseFormatTypeFilterService
} from 'modules/search/common/services/filters/course-format-type-filter.service';
import { CourseLabelFilterService } from 'modules/search/common/services/filters/course-label-filter.service';
import { CreditTypeFilterService } from 'modules/search/common/services/filters/credit-type-filter.service';
import { LsSearchFilterService } from 'modules/search/common/services/filters/ls-search-filter.service';
import { OrganizationFilterService } from 'modules/search/common/services/filters/organization-filter.service';
import { SubjectFilterService } from 'modules/search/common/services/filters/subject-filter.service';
import { TagSearchFilterService } from 'modules/search/common/services/filters/tag-search-filter.service';
import { TargetAudienceFilterService } from 'modules/search/common/services/filters/target-audience-filter.service';
import { SearchFiltersMappingService } from 'modules/search/common/services/search-filter-mapping.service';
import {
  TrainingCategoriesFilterService
} from 'modules/search/common/services/filters/training-categories-filter.service';


@Injectable()
export class SearchFiltersBMSMappingService extends SearchFiltersMappingService {
  protected override readonly mapping: Record<string, Type<BaseSearchFilterService>> = {
    'objectFilter': ObjectFilterService,
    'tagFilter': TagSearchFilterService,
    'courseFormatFilter': CourseFormatFilterService,
    'courseLabelFilter': CourseLabelFilterService,
    'creditTypeFilter': CreditTypeFilterService,
    'subjectFilter': SubjectFilterService,
    'targetAudiencesFilter': TargetAudienceFilterService,
    'lsFilter': LsSearchFilterService,
    'courseFormatTypeFilter': CourseFormatTypeFilterService,
    'organizationFilter': OrganizationFilterService,
    'trainingCategoriesFilter': TrainingCategoriesFilterService,
  };
}
