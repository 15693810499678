<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{label}}</h4>
</div>

<div class="modal-body">
  <!--Selected grid-->
  <div>
    <div class="panel panel-default standard-margin-bottom">
      <table class="table">
        <thead>
        <tr>
          <th style="width:50px"></th>
          <th class="control-label text-left">Selected {{label}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of selectedItems">
          <td>
            <button type="button" class="btn btn-xs btn-danger" (click)="removeItem(item)" title="Remove">
              <span class="fa fa-times"></span>
            </button>
          </td>
          <td class="table-overflow">{{item.path}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Search panel-->
  <div class="row standard-margin-bottom">
    <label for="keywordSearch" class="col-xs-12">Add More {{label}}</label>
    <div class="col-xs-8 col-sm-10 no-padding-right">
      <div class="input-group">
        <input type="search" class="form-control" id="keywordSearch" placeholder="Search by any part of name or id"
               [(ngModel)]="query" (keydown.enter)="search($event)" [disabled]="searching">
        <span class="input-group-btn">
            <button type="button" [ladda]="searching" class="btn btn-default" data-spinner-color="#000" title="Search"
                    (click)="search($event)" [disabled]="searching">
              <span class="fa fa-search"></span>
            </button>
          </span>
      </div>
    </div>
    <div class="col-xs-4 col-sm-2 no-padding-left text-right">
      <div class="btn-group">
        <button type="button" class="btn btn-default" title="Tree view" data-spinner-color="#000"
                (click)="changeSearchType()" [ngClass]="{'active': treeView}" [disabled]="searching">
          <span class="fa fa-level-down"></span></button>
        <button type="button" class="btn btn-default" title="List view" data-spinner-color="#000"
                (click)="changeSearchType()" [ngClass]="{'active': !treeView}" [disabled]="searching">
          <span class="fa fa-list"></span></button>
      </div>
    </div>
  </div>
  <div class="alert alert-sm alert-info" *ngIf="itemCount === 0">No {{label.toLowerCase()}} were found.</div>
  <!--Search result-->
  <div *ngIf="itemCount && !treeView">
    <div class="panel panel-default standard-margin-bottom">
      <table class="table standard-margin-bottom">
        <tbody>
        <tr *ngFor="let item of items">
          <td style="width:50px">
            <button *ngIf="!isItemAdded(item)" type="button" class="btn btn-success btn-xs" (click)="addItem(item)"
                    title="Add">
              <span class="fa fa-plus"></span>
            </button>
            <span *ngIf="isItemAdded(item)" class="text-primary fa fa-link" title="Added"></span>
          </td>
          <td class="table-overflow">
            <span>{{getPathToItem(item)}}</span>
            <span [innerHtml]="item.text"></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center">
      <ngb-pagination *ngIf="itemCount > itemsPerPage" [collectionSize]="itemCount" [maxSize]="10"
                      [rotate]="true" [ellipses]="false" (pageChange)="pageChanged($event)" [boundaryLinks]="true"
                      [disabled]="searching" [page]="currentPage" size="sm" class="pagination-sm">
        <ng-template ngbPaginationFirst>&laquo;</ng-template>
        <ng-template ngbPaginationLast>&raquo;</ng-template>
        <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
        <ng-template ngbPaginationNext>&rsaquo;</ng-template>
        <ng-template ngbPaginationNumber let-page>{{page}}</ng-template>
      </ngb-pagination>

    </div>
  </div>

  <div class="tree-view angular-ui-tree" *ngIf="treeView">
    <ul class="list-group standard-margin-bottom">
      <li class="list-group-item scrollable-after-some" *ngFor="let node of items">
        <ng-container [ngTemplateOutlet]="nodeItem"
                      [ngTemplateOutletContext]="{ node }"></ng-container>
      </li>
    </ul>
  </div>
  <ng-template #nodeItem let-node="node">
    <div class="tree-node tree-node-borderless">
      <div class="pull-left tree-expand-wrapper" *ngIf="node.hasChildren">
        <div class="tree-expand" (mimicButton)="expand(node)">
              <span class="fa"
                    [ngClass]="{'fa-caret-right': !node.expanded, 'fa-caret-down': node.expanded, 'fa-spinner fa-spin':node.loading}"></span>
        </div>
      </div>

      <div class="tree-node-wrapper">
        <div class="checkbox no-padding-vertical" [ngClass]="{'no-margin-left': !node.hasChildren}">
          <label class="">
            <input type="checkbox" name="" value="" [(ngModel)]="node.selected"
                   (change)="changeItem(node)"/>
            <span class="font-normal"
                  [innerHtml]="node.text"></span>
          </label>
        </div>

      </div>
      <ol [hidden]="!node.expanded" class="angular-ui-tree-nodes">
        <li *ngFor="let childNode of node.nodes">
          <ng-container [ngTemplateOutlet]="nodeItem" [ngTemplateOutletContext]="{ node:childNode }"></ng-container>
        </li>
      </ol>
    </div>
  </ng-template>
</div>

<div class="modal-footer" *ngIf="isModal">
  <button type="button" class="btn btn-warning" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-success" (click)="applyFilter()">Apply</button>
</div>
