import { NgModule } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { LmsCoreModule } from 'core/core.module';


@NgModule({
  imports: [
    LmsCoreModule,
  ],
  declarations: [AppComponent]
})
export class AppModule {}
