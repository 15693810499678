import { FactoryProvider } from '@angular/core';


export abstract class GTagService {
  sendClickEvent: (url: string, event: string) => void;
  sendPageView: ()=> void;
  skipNextPWEvent: ()=> void;
}

export function gTagServiceServiceFactory(injector: angular.auto.IInjectorService): GTagService {
  return injector.get<GTagService>('gtag');
}

export const gtagServiceProvider: FactoryProvider = {
  provide: GTagService,
  useFactory: gTagServiceServiceFactory,
  deps: ['$injector']
};
