import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchFilterItemUpgraded, ISearchFilterUpgraded } from '../../models/search-filters.models';
import { UrlParamHandler, IUrlParams } from 'core/navigation/models/navigation-url.model';
import { UrlService } from '@uirouter/core';
import { SuggestionsService } from 'modules/search/services/suggestions.service';
import { catchError, concat, distinctUntilChanged, Observable, of, Subject, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import _ from 'lodash';


@Component({
  selector: 'prompting-filter-dep',
  templateUrl: './prompting-filter.component.html'
})
export class PromptingFilterDepComponent implements OnInit, OnDestroy {
  static readonly selector = 'promptingFilter';
  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  @Input() isMultiple = false;

  choices$: Observable<ISearchFilterItemUpgraded[]>;
  choicesLoading = false;
  choicesInput$ = new Subject<string>();
  choices: ISearchFilterItemUpgraded[] = [];
  selectedItems: ISearchFilterItemUpgraded[] = [];
  singeItem: ISearchFilterItemUpgraded | null = null;
  status = {
    isOpen: this.isOpen,
    changed: false
  };

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  constructor(
    private suggestionsService: SuggestionsService,
    private urlService: UrlService,
  ) { }

  ngOnInit(): void {
    this.filter.multiple = this.isMultiple ? true : this.filter.multiple;
    this.filter.on('filtersChanged', () => {
      this.selectedItems = this.filter.selectedItems;
      this.singeItem = this.selectedItems[0];
    });
    this.refreshItems();
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filter.extractConditions();
      this.filter.selectedItems = _.filter(this.filter.selectedItems, (item) => {
        return _.some(this.filter.selectedOptions, function (option) {
          return option === item.value;
        });
      });

      this.filter.items = this.filter.selectedItems;
      this.selectedItems = this.filter.selectedItems;
      this.singeItem = this.selectedItems[0];
    }
  }

  trackByFn(item: ISearchFilterItemUpgraded) {
    return item.id;
  }

  compareFn(item1: ISearchFilterItemUpgraded, item2: ISearchFilterItemUpgraded) {
    return item1.id === item2.id;
  }

  refreshItems() {
    this.choices$ = concat(
      of([]), // default items
      this.choicesInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.choicesLoading = true),
        switchMap(term => this.suggestionsService.getSuggestions(term, this.filter.promptType)
          .pipe(
            catchError(() => of({ items: [] })), // empty list on error
            tap(() => this.choicesLoading = false),
            switchMap((res) => {
              return  of(_.map(res.items, (item) => {
                return {
                  id: parseInt(item.id, 10),
                  text: item.name,
                  value: item.id + ''
                };
              }));
            })
          )
        )
      )
    );
  }

  execFilter(item: ISearchFilterItemUpgraded) {
    if (!item.value) {
      return;
    }

    // Apply selection to search filter
    this.filter.exec(item.value);

    // We should manually add/remove selected item to the filter's item collection,
    // because it consists only from selected items.
    if (!_.includes(this.filter.selectedOptions, item.value)) {
      this.filter.items.push(item);
    } else {
      _.remove(this.filter.items, function (f) {
        return f.value === item.value;
      });
    }
  }
}
