import { Component } from '@angular/core';


@Component({
  selector: 'lms-main-application',
  templateUrl: './app.component.html'
})
export class AppComponent {
  static readonly selector = 'lmsMainApplication';
}
