import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsCoreModule } from 'core/core.module';
import { appModuleAjs } from 'downgraded/app.module.ajs';
import { GlobalConfig } from 'core/environment';
import { getMainMenuItems } from './menu-items';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { AppModule } from 'app/app.module';
import { LmsBmsModule } from './modules/lms-bms.module';
import runLMS from '../../app-runner';


@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
    UIRouterUpgradeModule.forRoot(),
    LmsCoreModule,
    LmsBmsModule,
    AppModule,
  ],
  declarations: [],
  // TODO Temporary workaround see @url: https://github.com/angular/angular/issues/17581
  providers: [{ provide: '$scope', useExisting: '$rootScope' }]
})
export class BmsModule implements DoBootstrap {
  constructor(
    private upgrade: UpgradeModule,
    private globalConfig: GlobalConfig,
    private siteSettingService: SiteSettingsService,
  ) {}

  ngDoBootstrap() {
    this.siteSettingService.getCurrent().then((siteSetting) => {
      const basePath = siteSetting.basePath;
      const defaultPortal = !basePath || basePath === 'lms';

      this.globalConfig.menu = getMainMenuItems(defaultPortal, basePath);
    });

    this.upgrade.bootstrap(document.getElementsByTagName('html')[0], [appModuleAjs.name]);
  }
}

runLMS<BmsModule>(BmsModule);

