import { downgradeInjectable } from '@angular/upgrade/static';
import { SamlService } from 'modules/integrations/services/saml.service';


declare const angular: angular.IAngularStatic;


export default angular
  .module('kmi.lms.core')
  .factory('samlSsoService', downgradeInjectable(SamlService));
