import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { IHttpCacheMapContext, IHttpCacheModel, TTL } from './http-cache-model';


@Injectable()
export class HttpCacheMapService {
  private cacheMap = new Map<string, IHttpCacheModel>();

  get(req: HttpRequest<any>): HttpResponse<any> | null {
    const entry = this.cacheMap.get(req.urlWithParams);

    if (!entry) {
      return null;
    }

    return this.isExpired(entry) ? null : entry.response.clone(
      { body: JSON.parse(JSON.stringify(entry.response.body)) });
  }

  set(req: HttpRequest<any>, res: HttpResponse<any>, context: IHttpCacheMapContext): void {
    const entry: IHttpCacheModel = {
      name: context.name,
      url: req.urlWithParams,
      response: res.clone({ body: JSON.parse(JSON.stringify(res.body)) }),
      entryTime: Date.now(),
      ttl: context.ttl
    };

    this.cacheMap.set(req.urlWithParams, entry);
    this.deleteExpiredCache();
  }

  clear(name: string) {
    this.cacheMap.forEach(entry => {
      if (entry.name === name) {
        this.cacheMap.delete(entry.url);
      }
    });
  }

  private deleteExpiredCache() {
    this.cacheMap.forEach(entry => {
      if (this.isExpired(entry)) {
        this.cacheMap.delete(entry.url);
      }
    });
  }

  private isExpired(entry: IHttpCacheModel): boolean {
    return entry.ttl !== TTL.NEVER_EXPIRE && (Date.now() - entry.entryTime) > entry.ttl;
  }
}
