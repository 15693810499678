import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { HttpClient, HttpContext } from '@angular/common/http';
import { IUserVolunteerAgreement } from '../models/user-volunteer-agreement.model';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


enum UserVolunteerAgreementEndpoints {
  GET = '/api-volunteers/users/:userId/agreements/:agreementId/',
  QUERY = '/api-volunteers/users/:userId/agreements/',
  POST = '/api-volunteers/users/:userId/agreements/:agreementId/',
  DELETE = '/api-volunteers/users/:userId/agreements/:agreementId/',
}


@Injectable()
export class UserVolunteerAgreementService {
  constructor(
    protected http: HttpClient,
  ) {
  }

  get(userId: number, agreementId: number, throwHttpErrors = false): Observable<IUserVolunteerAgreement> {
    return this.http.get<IUserVolunteerAgreement>(
      ElmsUtils.formatUrl(UserVolunteerAgreementEndpoints.GET, { userId: userId, agreementId: agreementId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  query(userId: number, throwHttpErrors = false): Observable<IUserVolunteerAgreement[]> {
    console.log('userId', userId);

    return this.http.get<IUserVolunteerAgreement[]>(
      ElmsUtils.formatUrl(UserVolunteerAgreementEndpoints.QUERY, { userId: userId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  post(userId: number, agreementId: number): Observable<IUserVolunteerAgreement> {
    return this.http.post<IUserVolunteerAgreement>(
      ElmsUtils.formatUrl(UserVolunteerAgreementEndpoints.POST, { userId: userId, agreementId: agreementId }),
      {}
    );
  }

  delete(userId: number, agreementId: number): Observable<void> {
    return this.http.delete<void>(
      ElmsUtils.formatUrl(UserVolunteerAgreementEndpoints.DELETE, { userId: userId, agreementId: agreementId }),
      {}
    );
  }
}
