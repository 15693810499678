<div *ngIf="filter.visible">
  <div *ngIf="!fullScreen">
    <ngb-accordion *ngIf="!fullScreen && filter.appearance !== 'button'"
                   #acc="ngbAccordion"
                   [activeIds]="isOpen?'panel-'+filter.term:''">
      <ngb-panel [id]="'panel-'+filter.term ">
        <ng-template ngbPanelTitle>
          <span class="pull-right fa fa-fw"
                [ngClass]="[ acc.isExpanded('panel-'+filter.term)?'fa-caret-up': 'fa-caret-down']"></span>
          {{filter.label}}
          <span class="badge badge-default display-none search-count-badge"
                *ngIf="filter.selectedItems.length>0">{{filter.selectedItems.length}}</span>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul class="content-list">
            <li class="content-list-item" *ngIf="filter.selectedItems.length>0">
              <div class="flex">
                <div class="flex-row">
                  <div class="flex-col flex-col-grow">
                    <a href="#" title="" (click)="openFilter()">{{filter.selectedItems.length}} filters selected</a>
                  </div>
                </div>
              </div>
            </li>
            <li class="content-list-item">
              <div class="flex">
                <div class="flex-row">
                  <div class="flex-col flex-col-grow">
                    <button type="button" class="btn btn-success btn-block" *ngIf="filter.selectedItems.length <= 0"
                            (click)="openFilter()">Select Filters</button>
                    <button type="button" class="btn btn-danger btn-block" *ngIf="filter.selectedItems.length > 0"
                            (click)="filter.clearSelection()">Remove Filters</button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>


    <button type="button" class="btn btn-default btn-block" *ngIf="filter.appearance === 'button'"
            (click)="openFilter()">{{filter.label}}</button>

  </div>
  <div *ngIf="fullScreen">
    <div [ngSwitch]="filter.popupOptions.component">
      <course-attribute-filter-dep *ngSwitchCase="'courseAttributeFilter'"
                                   [filter]="filter"></course-attribute-filter-dep>
      <competency-filter-dep *ngSwitchCase="'competencyFilter'" [filter]="filter"></competency-filter-dep>
      <session-label-filter-dep *ngSwitchCase="'sessionLabelFilter'" [filter]="filter"></session-label-filter-dep>
      <group-tags-search-filter-dep *ngSwitchCase="'groupTagsSearchFilter'"
                                    [filter]="filter"></group-tags-search-filter-dep>
    </div>
  </div>
</div>
