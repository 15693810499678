/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCachingInterceptor } from 'core/http-interceptors/http-caching-interceptor';
import { HttpRequestsCounterInterceptor } from 'core/http-interceptors/http-requests-counter-interceptor';
import { HttpAuthenticationInterceptor } from './http-authentication.interceptor';
import { HttpCommonHeaderInterceptor } from './http-common-headers.interceptor';
import { HttpRequestPermitInterceptor } from './http-request-permit.interceptor';
import { HttpRequestStatusInterceptor } from './http-request-status.interceptor';
import { HttpRequestErrorHandlerInterceptor } from './http-request-error-handler.interceptor';


export { PASS_HTTP_ERRORS_TOKEN } from './http-request-error-handler.interceptor';
export { IGNORE_AUTHORIZATION_TOKEN } from './http-authentication.interceptor';

/** Http interceptor providers in outside-in order */
// TODO Implement Interceptors to clean cache for specific ULRs,
//  example URL: '/a/course/:courseId/' `'response': function() { clearCache(); }`
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpCommonHeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestStatusInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpCachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestErrorHandlerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestPermitInterceptor, multi: true },
  HttpRequestsCounterInterceptor,
  { provide: HTTP_INTERCEPTORS, useExisting: HttpRequestsCounterInterceptor, multi: true },
];
