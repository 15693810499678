import * as _ from 'lodash';


export class BrowserController {
  constructor(private globalConfig: any) {
    this.checkFrames();
  }

  get currentBasePath() {
    const pathArray = _.trim(window.location.pathname, '/').split('/');
    const valueForCheck = '/' + pathArray.filter(function (segment) {
      return segment;
    }).join('/');

    // Fixed path to avoid problems with the path-related cookies
    if (_.trimEnd(window.location.pathname, '/') !== valueForCheck) {
      window.location.pathname = valueForCheck;
    }

    if (pathArray.length) {
      return pathArray[0];
    }
  }

  adjustBasePath(basePath: string): void {
    if (basePath) {
      this.globalConfig.base = ['/', basePath.replace(/^\/|\/$/g, ''), '/'].join('');
    }

    if (this.globalConfig.settings.view === 'admin' && this.globalConfig.base.indexOf('/admin') === -1) {
      this.globalConfig.base = this.globalConfig.base + 'admin/';
    }

    const baseTags = document.getElementsByTagName('base');
    let baseTag = baseTags.length ? baseTags[0] : null;

    if (!baseTag) {
      baseTag = document.createElement('base');
      document.getElementsByTagName('head')[0].appendChild(baseTag);
    }

    baseTag.setAttribute('href', this.globalConfig.base);
  }

  get inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  redirectTo502Error(code?: number) {
    const urlParts = ['/', _.trim(this.currentBasePath, '/'), '/502.html'];

    if (code) {
      urlParts.push('?code=' + code);
    }

    window.location.href = urlParts.join('');
  }

  checkFrames() {
    if (this.inIframe) {
      document.body.classList.add('iframe-body');
    }
  }
}
