import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchFilterUpgraded } from '../../models/search-filters.models';
import { UrlParamHandler, IUrlParams } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { UrlService } from '@uirouter/core';


@Component({
  selector: 'multi-choice-filter-dep',
  templateUrl: './multi-choice-filter.component.html'
})
export class MultiChoiceFilterDepComponent implements OnInit, OnDestroy {
  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  searchQuery:string|null = null;
  status = {
    changed: false
  };

  displayLimit:number;
  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  constructor(
    private urlService: UrlService,
  ) {  }

  get filteredFilterItems() {
    return _.filter(this.filter.items, (item) => {
      return this.searchQuery ? item.text.toLowerCase().includes(this.searchQuery.toLowerCase()) : true;
    }).slice(0, this.fullScreen ? undefined : this.displayLimit);
  }

  ngOnInit(): void {
    this.displayLimit = this.filter.displayLimit;

    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filter.extractConditions();

      if (!this.filter.visible) {
        this.searchQuery = null;
      }
    }
  }

  applyFilter() {
    const items = _.filter(this.filter.items, { selected: true });

    this.filter.clearSelection();
    _.forEach(items, (item) => {
      this.filter.exec(item.value, item.term);
    });

    this.isOpen = false;
    this.status.changed = false;
  }

  setStatus() {
    const selectedItems = _.filter(this.filter.items, { selected: true });

    this.status.changed = !_.isEqual(selectedItems, this.filter.selectedItems);
  }

  moreItems() {
    this.displayLimit = this.filter.items.length;

    return false;
  }

  lessItems() {
    this.displayLimit = this.filter.displayLimit;

    return false;
  }

  isAllSelected() {
    return _.every(this.filter.items, 'selected');
  }

  selectAll(items) {
    const value = !this.isAllSelected();

    items.forEach((item) => {
      if (item.selected !== value) {
        this.filter.exec(item.value, item.term);
      }

      item.selected = value;
    });
  }

  searchQueryChanged() {
    if (this.searchQuery?.length) {
      this.moreItems();
    } else {
      this.lessItems();
    }
  }
}
