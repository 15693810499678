import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchFilterItemUpgraded, ISearchFilterUpgraded } from '../../models/search-filters.models';
import { UrlService } from '@uirouter/core';
import { UrlParamHandler, IUrlParams } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';


@Component({
  selector: 'selected-search-filters-dep',
  templateUrl: './selected-search-filters.component.html'
})
export class SelectedSearchFiltersDepComponent implements OnInit, OnDestroy {
  static readonly selector = 'selectedSearchFilters';
  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  limitItems = 5;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  constructor(
    private urlService: UrlService,
  ) {  }

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
  }

  getText(item: ISearchFilterItemUpgraded): string {
    if (this.filter.itemTextFormatter) {
      return this.filter.itemTextFormatter(item);
    }

    return item.text;
  }

  getTitle(): string {
    const items = [];
    const selectedItems = this.filter.selectedItems.slice(0, this.limitItems);

    _.forEach(selectedItems, (item) => {
      items.push(this.getText(item));
    });

    if (selectedItems.length < this.filter.selectedItems.length) {
      items.push('and ' + (this.filter.selectedItems.length - this.limitItems) + ' more');
    }

    return items.join(',\n');
  }

  isNumber(item): boolean {
    return _.isNumber(item);
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filter.extractConditions();
    }
  }
}
